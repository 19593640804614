import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {Route, withRouter} from 'react-router-dom';
import NewInsightStep1 from './step1';
import AddComponents from '../add_components';
import NewInsightStep3 from './step3';
import Util from '../../../forecast-app/shared/util/util';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

class newInsight extends Component {
	constructor(props) {
		super(props);

		this.state = {
			category: null,
			components: [],
			insightName: '',
		};
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !!nextProps.viewer.company;
	}

	setCategory(category) {
		this.setState({category});
	}

	setComponents(components) {
		this.setState({components});
	}

	setName(insightName) {
		this.setState({insightName});
	}

	render() {
		if (!this.props.viewer.company) {
			return <div />;
		}
		const hasFinancials = CompanySetupUtil.hasFinance();
		return (
			<>
				<Route path={['/insights/new/step1', '/reports/new/step1']}>
					<NewInsightStep1
						labelsOptions={this.props.viewer.company.labels.edges.map(e => e.node)}
						newInsight={true}
						components={[]}
						name={this.state.insightName}
						chosenComponents={this.state.components}
						category={this.state.category}
						setCategory={this.setCategory.bind(this)}
						setComponents={this.setComponents.bind(this)}
						setName={this.setName.bind(this)}
						companyId={this.props.viewer.company.id}
						availableFeatureFlags={this.props.viewer.availableFeatureFlags}
						isUsingSchedulingPlanMode={this.props.viewer.company.isUsingSchedulingPlanMode}
						isUsingProjectAllocation={this.props.viewer.company.isUsingProjectAllocation}
						isUsingMixedMode={Util.isMixedAllocationModeEnabled(this.props.viewer.company)}
						hasPriorityLevels={this.props.viewer.company.priorityLevels.edges.length > 0}
					/>
				</Route>

				<Route path={['/insights/new/step2', '/reports/new/step2']}>
					<AddComponents
						noFinance={!CompanySetupUtil.hasFinance()}
						labelsOptions={this.props.viewer.company.labels.edges.map(e => e.node)}
						newInsight={true}
						components={[]}
						name={this.state.insightName}
						chosenComponents={this.state.components}
						category={this.state.category}
						setCategory={this.setCategory.bind(this)}
						setComponents={this.setComponents.bind(this)}
						setName={this.setName.bind(this)}
						companyId={this.props.viewer.company.id}
						hasFinancials={hasFinancials}
						isUsingSchedulingPlanMode={this.props.viewer.company.isUsingSchedulingPlanMode}
					/>
				</Route>

				<Route path={['/insights/new/step3', '/reports/new/step3']}>
					<NewInsightStep3
						labelsOptions={this.props.viewer.company.labels.edges.map(e => e.node)}
						newInsight={true}
						components={[]}
						name={this.state.insightName}
						chosenComponents={this.state.components}
						category={this.state.category}
						setCategory={this.setCategory.bind(this)}
						setComponents={this.setComponents.bind(this)}
						setName={this.setName.bind(this)}
						companyId={this.props.viewer.company.id}
						hasFinancials={hasFinancials}
						availableFeatureFlags={this.props.viewer.availableFeatureFlags}
					/>
				</Route>
			</>
		);
	}
}

const newInsightQuery = graphql`
	query newInsight_Query {
		viewer {
			actualPersonId
			component(name: "new_insight")
			...newInsight_viewer
		}
	}
`;

export {newInsightQuery};

export default withRouter(
	createFragmentContainer(newInsight, {
		viewer: graphql`
			fragment newInsight_viewer on Viewer {
				availableFeatureFlags {
					key
				}
				company {
					id
					isUsingSchedulingPlanMode
					isUsingProjectAllocation
					isUsingMixedAllocation
					labels(first: 1000000) {
						edges {
							node {
								id
								name
							}
						}
					}
					priorityLevels {
						edges {
							node {
								id
							}
						}
					}
				}
			}
		`,
	})
);
