import {theEyeOptionsNoGrouping as TaskReportV2Eye} from '../../../forecast-app/reports-tab/report/task-report-page/TaskReportTheEyeUtil';
import {HIDDEN_FEATURES, PERIOD_TYPE, REPORT_TYPE} from '../../../constants';
import {getPeriodEyeOptions} from '../../../components/insights/utilization-report/Period';
import {
	getMonthByMonthEyeOptions,
	getPeriodEyeOptions as getProjectPortfolioReportPeriodEyeOptions,
} from '../../../components/insights/project-portfolio-report/period/ProjectPortfolioReportPeriodEyeOptions';
import {UTILIZATION_DROPDOWN} from '../../../components/insights/utilization-report/UtilizationReport';
import {createSavedOptions} from '../../../the_eye_util';
import moment from 'moment';
import {PROJECT_PORTFOLIO_SINGLE_NUMBER} from '../../../components/insights/project-portfolio-report/ProjectPortfolioSingleNumberOptions';
import Util from '../../../forecast-app/shared/util/util';
import {theEyeOptionsNoGrouping as TimeRegReportV2Eye} from '../../../forecast-app/reports-tab/report/time-registration-report-page/TimeRegistrationReportPageEyeUtil';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

export const getEyeApplied = (
	reportType,
	isUsingProjectAllocation,
	isUsingSchedulingPlanMode,
	isUsingMixedMode,
	hasPriorityLevels
) => {
	switch (reportType) {
		case REPORT_TYPE.TIME_REG: {
			return createSavedOptions(TimeRegReportV2Eye());
		}
		case REPORT_TYPE.TASK_REPORT: {
			return createSavedOptions(TaskReportV2Eye());
		}

		case REPORT_TYPE.PROJECT_PORTFOLIO_REPORT: {
			const hasFinance = CompanySetupUtil.hasFinance();
			const hasBaseline = Util.hasBaselineModule();
			const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
			const hasRetainerPeriodRead = Util.AuthorizeViewerAccess('project-periods');
			return createSavedOptions([
				...getProjectPortfolioReportPeriodEyeOptions(
					hasFinance,
					hasBaseline,
					hasRevenueWithoutCostAccess,
					hasRetainerPeriodRead,
					hasPriorityLevels
				),
				...getMonthByMonthEyeOptions(hasPriorityLevels),
			]);
		}
		case REPORT_TYPE.UTILIZATION_REPORT: {
			return createSavedOptions(
				getPeriodEyeOptions(isUsingProjectAllocation, isUsingSchedulingPlanMode, isUsingMixedMode)
			);
		}
		default: {
			return 'No default eye options provided!';
		}
	}
};

export const getGroupingOne = reportType => {
	switch (reportType) {
		case REPORT_TYPE.TIME_REG:
			return null;
		case REPORT_TYPE.UTILIZATION_REPORT:
			return UTILIZATION_DROPDOWN.BILLABLE_UTILIZATION;
		case REPORT_TYPE.PROJECT_PORTFOLIO_REPORT:
			return !Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)
				? PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION
				: PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION;
	}
};

export const getPeriodType = reportType => {
	switch (reportType) {
		case REPORT_TYPE.UTILIZATION_REPORT:
		case REPORT_TYPE.PROJECT_PORTFOLIO_REPORT:
			return PERIOD_TYPE.YEAR;
		default:
			return null;
	}
};

export const getPeriodDate = reportType => {
	switch (reportType) {
		case REPORT_TYPE.UTILIZATION_REPORT:
		case REPORT_TYPE.PROJECT_PORTFOLIO_REPORT:
			return JSON.stringify(moment().year());
		default:
			return null;
	}
};
