export const permissions = [
	{value: 'NO_LOGIN', label: 'Virtual'},
	{value: 'CLIENT', label: 'Client'},
	{value: 'RESTRICTED', label: 'Collaborator'},
	{value: 'NO_FINANCIAL', label: 'Coordinator'},
	{value: 'SEMI_FULL', label: 'Manager'},
	{value: 'FULL', label: 'Controller'},
	{value: 'ADMIN', label: 'Admin'},
	{value: 'SHARE_PROJECT_TIMELINE', label: 'ShareProjectTimeline'},
];

export const USER_LOCALE_STORAGE_KEY = 'userLocale';

export const profileLocales = {
	ENGLISH_US: {
		html_lang_attribute: 'en-US',
		formatting_locale: 'en-US',
		moment_locale: 'en-US',
	},
	ENGLISH_UK: {
		html_lang_attribute: 'en-GB',
		formatting_locale: 'en-GB',
		moment_locale: 'en-GB',
	},
	ENGLISH_EU: {
		html_lang_attribute: 'en-150',
		formatting_locale: 'en-dk',
		moment_locale: 'en-GB',
	},
	SPANISH: {
		html_lang_attribute: 'es',
		formatting_locale: 'es',
		moment_locale: 'es',
	},
	DANISH: {
		html_lang_attribute: 'da',
		formatting_locale: 'da',
		moment_locale: 'da',
	},
	FRENCH: {
		html_lang_attribute: 'fr',
		formatting_locale: 'fr',
		moment_locale: 'fr',
	},
};

export const isoCountries = [
	{value: 'AF', label: 'Afghanistan'},
	{value: 'AX', label: 'Aland Islands'},
	{value: 'AL', label: 'Albania'},
	{value: 'DZ', label: 'Algeria'},
	{value: 'AS', label: 'American Samoa'},
	{value: 'AD', label: 'Andorra'},
	{value: 'AO', label: 'Angola'},
	{value: 'AI', label: 'Anguilla'},
	{value: 'AQ', label: 'Antarctica'},
	{value: 'AG', label: 'Antigua And Barbuda'},
	{value: 'AR', label: 'Argentina'},
	{value: 'AM', label: 'Armenia'},
	{value: 'AW', label: 'Aruba'},
	{value: 'AU', label: 'Australia'},
	{value: 'AT', label: 'Austria'},
	{value: 'AZ', label: 'Azerbaijan'},
	{value: 'BS', label: 'Bahamas'},
	{value: 'BH', label: 'Bahrain'},
	{value: 'BD', label: 'Bangladesh'},
	{value: 'BB', label: 'Barbados'},
	{value: 'BY', label: 'Belarus'},
	{value: 'BE', label: 'Belgium'},
	{value: 'BZ', label: 'Belize'},
	{value: 'BJ', label: 'Benin'},
	{value: 'BM', label: 'Bermuda'},
	{value: 'BT', label: 'Bhutan'},
	{value: 'BO', label: 'Bolivia'},
	{value: 'BA', label: 'Bosnia And Herzegovina'},
	{value: 'BW', label: 'Botswana'},
	{value: 'BV', label: 'Bouvet Island'},
	{value: 'BR', label: 'Brazil'},
	{value: 'IO', label: 'British Indian Ocean Territory'},
	{value: 'BN', label: 'Brunei Darussalam'},
	{value: 'BG', label: 'Bulgaria'},
	{value: 'BF', label: 'Burkina Faso'},
	{value: 'BI', label: 'Burundi'},
	{value: 'KH', label: 'Cambodia'},
	{value: 'CM', label: 'Cameroon'},
	{value: 'CA', label: 'Canada'},
	{value: 'KY', label: 'Cayman Islands'},
	{value: 'CF', label: 'Central African Republic'},
	{value: 'TD', label: 'Chad'},
	{value: 'CL', label: 'Chile'},
	{value: 'CN', label: 'China'},
	{value: 'CX', label: 'Christmas Island'},
	{value: 'CC', label: 'Cocos (Keeling) Islands'},
	{value: 'CO', label: 'Colombia'},
	{value: 'KM', label: 'Comoros'},
	{value: 'CG', label: 'Congo'},
	{value: 'CD', label: 'Congo, Democratic Republic'},
	{value: 'CK', label: 'Cook Islands'},
	{value: 'CR', label: 'Costa Rica'},
	{value: 'CI', label: "Côte d'Ivoire"},
	{value: 'HR', label: 'Croatia'},
	{value: 'CU', label: 'Cuba'},
	{value: 'CY', label: 'Cyprus'},
	{value: 'CZ', label: 'Czechia'},
	{value: 'DK', label: 'Denmark'},
	{value: 'DJ', label: 'Djibouti'},
	{value: 'DM', label: 'Dominica'},
	{value: 'DO', label: 'Dominican Republic'},
	{value: 'EC', label: 'Ecuador'},
	{value: 'EG', label: 'Egypt'},
	{value: 'SV', label: 'El Salvador'},
	{value: 'GQ', label: 'Equatorial Guinea'},
	{value: 'ER', label: 'Eritrea'},
	{value: 'EE', label: 'Estonia'},
	{value: 'ET', label: 'Ethiopia'},
	{value: 'FK', label: 'Falkland Islands (Malvinas)'},
	{value: 'FO', label: 'Faroe Islands'},
	{value: 'FJ', label: 'Fiji'},
	{value: 'FI', label: 'Finland'},
	{value: 'FR', label: 'France'},
	{value: 'GF', label: 'French Guiana'},
	{value: 'PF', label: 'French Polynesia'},
	{value: 'TF', label: 'French Southern Territories'},
	{value: 'GA', label: 'Gabon'},
	{value: 'GM', label: 'Gambia'},
	{value: 'GE', label: 'Georgia'},
	{value: 'DE', label: 'Germany'},
	{value: 'GH', label: 'Ghana'},
	{value: 'GI', label: 'Gibraltar'},
	{value: 'GR', label: 'Greece'},
	{value: 'GL', label: 'Greenland'},
	{value: 'GD', label: 'Grenada'},
	{value: 'GP', label: 'Guadeloupe'},
	{value: 'GU', label: 'Guam'},
	{value: 'GT', label: 'Guatemala'},
	{value: 'GG', label: 'Guernsey'},
	{value: 'GN', label: 'Guinea'},
	{value: 'GW', label: 'Guinea-Bissau'},
	{value: 'GY', label: 'Guyana'},
	{value: 'HT', label: 'Haiti'},
	{value: 'HM', label: 'Heard Is. & Mcdonald Islands'},
	{value: 'VA', label: 'Holy See'},
	{value: 'HN', label: 'Honduras'},
	{value: 'HK', label: 'Hong Kong'},
	{value: 'HU', label: 'Hungary'},
	{value: 'IS', label: 'Iceland'},
	{value: 'IN', label: 'India'},
	{value: 'ID', label: 'Indonesia'},
	{value: 'IR', label: 'Iran, Islamic Republic Of'},
	{value: 'IQ', label: 'Iraq'},
	{value: 'IE', label: 'Ireland'},
	{value: 'IM', label: 'Isle Of Man'},
	{value: 'IL', label: 'Israel'},
	{value: 'IT', label: 'Italy'},
	{value: 'JM', label: 'Jamaica'},
	{value: 'JP', label: 'Japan'},
	{value: 'JE', label: 'Jersey'},
	{value: 'JO', label: 'Jordan'},
	{value: 'KZ', label: 'Kazakhstan'},
	{value: 'KE', label: 'Kenya'},
	{value: 'KI', label: 'Kiribati'},
	{value: 'KR', label: 'Korea, Republic of'},
	{value: 'KW', label: 'Kuwait'},
	{value: 'KG', label: 'Kyrgyzstan'},
	{value: 'LA', label: "Lao People's Democratic Republic"},
	{value: 'LV', label: 'Latvia'},
	{value: 'LB', label: 'Lebanon'},
	{value: 'LS', label: 'Lesotho'},
	{value: 'LR', label: 'Liberia'},
	{value: 'LY', label: 'Libya'},
	{value: 'LI', label: 'Liechtenstein'},
	{value: 'LT', label: 'Lithuania'},
	{value: 'LU', label: 'Luxembourg'},
	{value: 'MO', label: 'Macao'},
	{value: 'MK', label: 'Macedonia'},
	{value: 'MG', label: 'Madagascar'},
	{value: 'MW', label: 'Malawi'},
	{value: 'MY', label: 'Malaysia'},
	{value: 'MV', label: 'Maldives'},
	{value: 'ML', label: 'Mali'},
	{value: 'MT', label: 'Malta'},
	{value: 'MH', label: 'Marshall Islands'},
	{value: 'MQ', label: 'Martinique'},
	{value: 'MR', label: 'Mauritania'},
	{value: 'MU', label: 'Mauritius'},
	{value: 'YT', label: 'Mayotte'},
	{value: 'MX', label: 'Mexico'},
	{value: 'FM', label: 'Micronesia (Federated States of)'},
	{value: 'MD', label: 'Moldova, Republic of'},
	{value: 'MC', label: 'Monaco'},
	{value: 'MN', label: 'Mongolia'},
	{value: 'ME', label: 'Montenegro'},
	{value: 'MS', label: 'Montserrat'},
	{value: 'MA', label: 'Morocco'},
	{value: 'MZ', label: 'Mozambique'},
	{value: 'MM', label: 'Myanmar'},
	{value: 'NA', label: 'Namibia'},
	{value: 'NR', label: 'Nauru'},
	{value: 'NP', label: 'Nepal'},
	{value: 'NL', label: 'Netherlands'},
	{value: 'NC', label: 'New Caledonia'},
	{value: 'NZ', label: 'New Zealand'},
	{value: 'NI', label: 'Nicaragua'},
	{value: 'NE', label: 'Niger'},
	{value: 'NG', label: 'Nigeria'},
	{value: 'NU', label: 'Niue'},
	{value: 'NF', label: 'Norfolk Island'},
	{value: 'MP', label: 'Northern Mariana Islands'},
	{value: 'NO', label: 'Norway'},
	{value: 'OM', label: 'Oman'},
	{value: 'PK', label: 'Pakistan'},
	{value: 'PW', label: 'Palau'},
	{value: 'PS', label: 'Palestine, State of'},
	{value: 'PA', label: 'Panama'},
	{value: 'PG', label: 'Papua New Guinea'},
	{value: 'PY', label: 'Paraguay'},
	{value: 'PE', label: 'Peru'},
	{value: 'PH', label: 'Philippines'},
	{value: 'PN', label: 'Pitcairn'},
	{value: 'PL', label: 'Poland'},
	{value: 'PT', label: 'Portugal'},
	{value: 'PR', label: 'Puerto Rico'},
	{value: 'QA', label: 'Qatar'},
	{value: 'RE', label: 'Réunion'},
	{value: 'RO', label: 'Romania'},
	{value: 'RU', label: 'Russian Federation'},
	{value: 'RW', label: 'Rwanda'},
	{value: 'BL', label: 'Saint Barthélemy'},
	{value: 'SH', label: 'Saint Helena'},
	{value: 'KN', label: 'Saint Kitts And Nevis'},
	{value: 'LC', label: 'Saint Lucia'},
	{value: 'MF', label: 'Saint Martin'},
	{value: 'PM', label: 'Saint Pierre And Miquelon'},
	{value: 'VC', label: 'Saint Vincent and the Grenadines'},
	{value: 'WS', label: 'Samoa'},
	{value: 'SM', label: 'San Marino'},
	{value: 'ST', label: 'Sao Tome And Principe'},
	{value: 'SA', label: 'Saudi Arabia'},
	{value: 'SN', label: 'Senegal'},
	{value: 'RS', label: 'Serbia'},
	{value: 'SC', label: 'Seychelles'},
	{value: 'SL', label: 'Sierra Leone'},
	{value: 'SG', label: 'Singapore'},
	{value: 'SK', label: 'Slovakia'},
	{value: 'SI', label: 'Slovenia'},
	{value: 'SB', label: 'Solomon Islands'},
	{value: 'SO', label: 'Somalia'},
	{value: 'ZA', label: 'South Africa'},
	{value: 'GS', label: 'S. Georgia & S. Sandwich Is'},
	{value: 'ES', label: 'Spain'},
	{value: 'LK', label: 'Sri Lanka'},
	{value: 'SD', label: 'Sudan'},
	{value: 'SR', label: 'Suriname'},
	{value: 'SJ', label: 'Svalbard And Jan Mayen'},
	{value: 'SZ', label: 'Swaziland'},
	{value: 'SE', label: 'Sweden'},
	{value: 'CH', label: 'Switzerland'},
	{value: 'SY', label: 'Syrian Arab Republic'},
	{value: 'TW', label: 'Taiwan'},
	{value: 'TJ', label: 'Tajikistan'},
	{value: 'TZ', label: 'Tanzania, United Republic of'},
	{value: 'TH', label: 'Thailand'},
	{value: 'TL', label: 'Timor-Leste'},
	{value: 'TG', label: 'Togo'},
	{value: 'TK', label: 'Tokelau'},
	{value: 'TO', label: 'Tonga'},
	{value: 'TT', label: 'Trinidad And Tobago'},
	{value: 'TN', label: 'Tunisia'},
	{value: 'TR', label: 'Turkey'},
	{value: 'TM', label: 'Turkmenistan'},
	{value: 'TC', label: 'Turks And Caicos Islands'},
	{value: 'TV', label: 'Tuvalu'},
	{value: 'UG', label: 'Uganda'},
	{value: 'UA', label: 'Ukraine'},
	{value: 'AE', label: 'United Arab Emirates'},
	{value: 'GB', label: 'United Kingdom'},
	{value: 'US', label: 'United States'},
	{value: 'UY', label: 'Uruguay'},
	{value: 'UZ', label: 'Uzbekistan'},
	{value: 'VU', label: 'Vanuatu'},
	{value: 'VE', label: 'Venezuela'},
	{value: 'VN', label: 'Viet Nam'},
	{value: 'VG', label: 'Virgin Islands, British'},
	{value: 'VI', label: 'Virgin Islands, U.S.'},
	{value: 'WF', label: 'Wallis And Futuna'},
	{value: 'EH', label: 'Western Sahara'},
	{value: 'YE', label: 'Yemen'},
	{value: 'ZM', label: 'Zambia'},
	{value: 'ZW', label: 'Zimbabwe'},
];

export const currencies = [
	{value: 'ARS', label: 'Argentine peso - ARS ($)'},
	{value: 'AUD', label: 'Australian dollar - AUD ($)'},
	{value: 'BRL', label: 'Brazilian real - BRL (R$)'},
	{value: 'CAD', label: 'Canadian dollar - CAD ($)'},
	{value: 'CNY', label: 'Chinese yuan - CNY (¥)'},
	{value: 'CZK', label: 'Czech koruna - CZK (Kč)'},
	{value: 'DKK', label: 'Danish kroner - DKK (kr.)'},
	{value: 'EUR', label: 'Euro - EUR (€)'},
	{value: 'HKD', label: 'Hong Kong dollar - HKD ($)'},
	{value: 'HUF', label: 'Hungarian forint - HUF (Ft)'},
	{value: 'AED', label: 'United Arab Emirates dirham - AED (د.إ)'},
	{value: 'INR', label: 'Indian rupee - INR (₹)'},
	{value: 'JPY', label: 'Japanese yen - JPY (¥)'},
	{value: 'MXN', label: 'Mexican peso - MXN ($)'},
	{value: 'NZD', label: 'New Zealand dollar - NZD ($)'},
	{value: 'NOK', label: 'Norwegian krone - NOK (kr.)'},
	{value: 'PHP', label: 'Philippine peso - PHP (₱)'},
	{value: 'PLN', label: 'Polish złoty - PLN (zł)'},
	{value: 'GBP', label: 'Pound sterling - GBP (£)'},
	{value: 'RUB', label: 'Russian ruble - RUB (₽)'},
	{value: 'SGD', label: 'Singapore dollar - SGD ($)'},
	{value: 'ZAR', label: 'South African rand - ZAR (R)'},
	{value: 'KRW', label: 'South Korean won - KRW (₩)'},
	{value: 'SEK', label: 'Swedish krona - SEK (kr.)'},
	{value: 'CHF', label: 'Swiss franc - CHF (Fr.)'},
	{value: 'TZS', label: 'Tanzanian shilling - TZS (TSh)'},
	{value: 'THB', label: 'Thai baht - THB (฿)'},
	{value: 'TRY', label: 'Turkish lira - TRY (₺)'},
	{value: 'TTD', label: 'Trinidad and Tobago dollar - TTD ($)'},
	{value: 'UAH', label: 'Ukrainian Hryvnia - UAH (₴)'},
	{value: 'USD', label: 'United States dollar - USD ($)'},
];

export const fileExtensions = [
	'jpeg',
	'jpg',
	'ai',
	'avi',
	'doc',
	'eps',
	'gif',
	'indd',
	'm4v',
	'mov',
	'mp4',
	'mpg',
	'pdf',
	'png',
	'ppt',
	'psd',
	'rar',
	'rtf',
	'svg',
	'txt',
	'xls',
	'zip',
	'file',
];

export const colors = [
	'#c279d1',
	'#e7abab',
	'#ddc4bf',
	'#393ac4',
	'#ea4871',
	'#fff4d8',
	'#c5c1df',
	'#a1b7e7',
	'#6e0fea',
	'#817dec',
	'#529f79',
	'#b8e986',
	'#df448b',
	'#eaeaea',
];

export const projectColors = [
	'#BC97F5',
	'#CE93D8',
	'#F98BD2',
	'#F69695',
	'#FFCBD0',
	'#FFDF83',
	'#FDEF95',
	'#7DEAB7',
	'#80CBC3',
	'#8AC7FF',
	'#B9DCFF',
	'#E6E6ED',
];

export const projectHoverColors = {
	'#bc97f5': '#8327FC',
	'#ce93d8': '#AB33C2',
	'#f98bd2': '#FF3DAE',
	'#f69695': '#FB3128',
	'#ffcbd0': '#FD5A53',
	'#ffdf83': '#FFAD0A',
	'#fdef95': '#FAD900',
	'#7deab7': '#23DC85',
	'#80cbc3': '#00998A',
	'#8ac7ff': '#1F9CFF',
	'#b9dcff': '#4DB0FF',
	'#e6e6ed': '#AEAEBC',
};

export const oldColors = [
	'#392664',
	'#48B8FF',
	'#3E5CF2',
	'#FF7C75',
	'#EA87FF',
	'#004C71',
	'#ED01DB',
	'#533891',
	'#006CB0',
	'#791FFF',
	'#C8C62B',
	'#6C0E89',
	'#83AA86',
	'#AD2929', // * vampire blood
	'#F99693',
	'#449F52',
	'#E3E190',
	'#67747C', // * Dark blue grey
	'#4B9600', // * Dark green
	'#E9B9F2',
	'#882EFF',
	'#0F21E5',
	'#A07C06', // * golden
	'#80F2B8',
	'#2A1951',
	'#BBBBBB',
	'#ED7D1A',
	'#9414BB',
	'#5282B0',
	'#444444',
];

//green, yellow, red; don't change order as it will affect sorting projects by status in project lists
export const projectStatusColors = ['#33cc33', '#ffc107', '#ff3300'];

export const timezones = [
	{label: '(GMT-11:00) Niue', value: 'Pacific/Niue'},
	{label: '(GMT-11:00) Pago Pago', value: 'Pacific/Pago_Pago'},
	{label: '(GMT-10:00) Hawaii Time', value: 'Pacific/Honolulu'},
	{label: '(GMT-10:00) Rarotonga', value: 'Pacific/Rarotonga'},
	{label: '(GMT-10:00) Tahiti', value: 'Pacific/Tahiti'},
	{label: '(GMT-09:30) Marquesas', value: 'Pacific/Marquesas'},
	{label: '(GMT-09:00) Alaska Time', value: 'America/Anchorage'},
	{label: '(GMT-09:00) Gambier', value: 'Pacific/Gambier'},
	{label: '(GMT-08:00) Pacific Time', value: 'America/Los_Angeles'},
	{label: '(GMT-08:00) Pacific Time - Tijuana', value: 'America/Tijuana'},
	{label: '(GMT-08:00) Pacific Time - Vancouver', value: 'America/Vancouver'},
	{label: '(GMT-08:00) Pacific Time - Whitehorse', value: 'America/Whitehorse'},
	{label: '(GMT-08:00) Pitcairn', value: 'Pacific/Pitcairn'},
	{label: '(GMT-07:00) Mountain Time - Dawson Creek', value: 'America/Dawson_Creek'},
	{label: '(GMT-07:00) Mountain Time', value: 'America/Denver'},
	{label: '(GMT-07:00) Mountain Time - Edmonton', value: 'America/Edmonton'},
	{label: '(GMT-07:00) Mountain Time - Hermosillo', value: 'America/Hermosillo'},
	{label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', value: 'America/Mazatlan'},
	{label: '(GMT-07:00) Mountain Time - Arizona', value: 'America/Phoenix'},
	{label: '(GMT-07:00) Mountain Time - Yellowknife', value: 'America/Yellowknife'},
	{label: '(GMT-06:00) Belize', value: 'America/Belize'},
	{label: '(GMT-06:00) Central Time', value: 'America/Chicago'},
	{label: '(GMT-06:00) Costa Rica', value: 'America/Costa_Rica'},
	{label: '(GMT-06:00) El Salvador', value: 'America/El_Salvador'},
	{label: '(GMT-06:00) Guatemala', value: 'America/Guatemala'},
	{label: '(GMT-06:00) Managua', value: 'America/Managua'},
	{label: '(GMT-06:00) Central Time - Mexico City', value: 'America/Mexico_City'},
	{label: '(GMT-06:00) Central Time - Regina', value: 'America/Regina'},
	{label: '(GMT-06:00) Central Time - Tegucigalpa', value: 'America/Tegucigalpa'},
	{label: '(GMT-06:00) Central Time - Winnipeg', value: 'America/Winnipeg'},
	{label: '(GMT-06:00) Galapagos', value: 'Pacific/Galapagos'},
	{label: '(GMT-05:00) Bogota', value: 'America/Bogota'},
	{label: '(GMT-05:00) America Cancun', value: 'America/Cancun'},
	{label: '(GMT-05:00) Cayman', value: 'America/Cayman'},
	{label: '(GMT-05:00) Guayaquil', value: 'America/Guayaquil'},
	{label: '(GMT-05:00) Havana', value: 'America/Havana'},
	{label: '(GMT-05:00) Eastern Time - Iqaluit', value: 'America/Iqaluit'},
	{label: '(GMT-05:00) Jamaica', value: 'America/Jamaica'},
	{label: '(GMT-05:00) Lima', value: 'America/Lima'},
	{label: '(GMT-05:00) Nassau', value: 'America/Nassau'},
	{label: '(GMT-05:00) Eastern Time', value: 'America/New_York'},
	{label: '(GMT-05:00) Panama', value: 'America/Panama'},
	{label: '(GMT-05:00) Port-au-Prince', value: 'America/Port-au-Prince'},
	{label: '(GMT-05:00) Rio Branco', value: 'America/Rio_Branco'},
	{label: '(GMT-05:00) Eastern Time - Toronto', value: 'America/Toronto'},
	{label: '(GMT-05:00) Easter Island', value: 'Pacific/Easter'},
	{label: '(GMT-04:30) Caracas', value: 'America/Caracas'},
	{label: '(GMT-03:00) Asuncion', value: 'America/Asuncion'},
	{label: '(GMT-04:00) Barbados', value: 'America/Barbados'},
	{label: '(GMT-04:00) Boa Vista', value: 'America/Boa_Vista'},
	{label: '(GMT-03:00) Campo Grande', value: 'America/Campo_Grande'},
	{label: '(GMT-03:00) Cuiaba', value: 'America/Cuiaba'},
	{label: '(GMT-04:00) Curacao', value: 'America/Curacao'},
	{label: '(GMT-04:00) Grand Turk', value: 'America/Grand_Turk'},
	{label: '(GMT-04:00) Guyana', value: 'America/Guyana'},
	{label: '(GMT-04:00) Atlantic Time - Halifax', value: 'America/Halifax'},
	{label: '(GMT-04:00) La Paz', value: 'America/La_Paz'},
	{label: '(GMT-04:00) Manaus', value: 'America/Manaus'},
	{label: '(GMT-04:00) Martinique', value: 'America/Martinique'},
	{label: '(GMT-04:00) Port of Spain', value: 'America/Port_of_Spain'},
	{label: '(GMT-04:00) Porto Velho', value: 'America/Porto_Velho'},
	{label: '(GMT-04:00) Puerto Rico', value: 'America/Puerto_Rico'},
	{label: '(GMT-04:00) Santo Domingo', value: 'America/Santo_Domingo'},
	{label: '(GMT-04:00) Thule', value: 'America/Thule'},
	{label: '(GMT-04:00) Bermuda', value: 'Atlantic/Bermuda'},
	{label: '(GMT-03:30) Newfoundland Time - St. Johns', value: 'America/St_Johns'},
	{label: '(GMT-03:00) Araguaina', value: 'America/Araguaina'},
	{label: '(GMT-03:00) Buenos Aires', value: 'America/Argentina/Buenos_Aires'},
	{label: '(GMT-03:00) Salvador', value: 'America/Bahia'},
	{label: '(GMT-03:00) Belem', value: 'America/Belem'},
	{label: '(GMT-03:00) Cayenne', value: 'America/Cayenne'},
	{label: '(GMT-03:00) Fortaleza', value: 'America/Fortaleza'},
	{label: '(GMT-03:00) Godthab', value: 'America/Godthab'},
	{label: '(GMT-03:00) Maceio', value: 'America/Maceio'},
	{label: '(GMT-03:00) Miquelon', value: 'America/Miquelon'},
	{label: '(GMT-03:00) Montevideo', value: 'America/Montevideo'},
	{label: '(GMT-03:00) Paramaribo', value: 'America/Paramaribo'},
	{label: '(GMT-03:00) Recife', value: 'America/Recife'},
	{label: '(GMT-03:00) Santiago', value: 'America/Santiago'},
	{label: '(GMT-02:00) Sao Paulo', value: 'America/Sao_Paulo'},
	{label: '(GMT-03:00) Palmer', value: 'Antarctica/Palmer'},
	{label: '(GMT-03:00) Rothera', value: 'Antarctica/Rothera'},
	{label: '(GMT-03:00) Stanley', value: 'Atlantic/Stanley'},
	{label: '(GMT-02:00) Noronha', value: 'America/Noronha'},
	{label: '(GMT-02:00) South Georgia', value: 'Atlantic/South_Georgia'},
	{label: '(GMT-01:00) Scoresbysund', value: 'America/Scoresbysund'},
	{label: '(GMT-01:00) Azores', value: 'Atlantic/Azores'},
	{label: '(GMT-01:00) Cape Verde', value: 'Atlantic/Cape_Verde'},
	{label: '(GMT+00:00) Abidjan', value: 'Africa/Abidjan'},
	{label: '(GMT+00:00) Accra', value: 'Africa/Accra'},
	{label: '(GMT+00:00) Bissau', value: 'Africa/Bissau'},
	{label: '(GMT+00:00) Casablanca', value: 'Africa/Casablanca'},
	{label: '(GMT+00:00) El Aaiun', value: 'Africa/El_Aaiun'},
	{label: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia'},
	{label: '(GMT+00:00) Danmarkshavn', value: 'America/Danmarkshavn'},
	{label: '(GMT+00:00) Canary Islands', value: 'Atlantic/Canary'},
	{label: '(GMT+00:00) Faeroe', value: 'Atlantic/Faroe'},
	{label: '(GMT+00:00) Reykjavik', value: 'Atlantic/Reykjavik'},
	{label: '(GMT+00:00) GMT (no daylight saving)', value: 'Etc/GMT'},
	{label: '(GMT+00:00) Dublin', value: 'Europe/Dublin'},
	{label: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon'},
	{label: '(GMT+00:00) London', value: 'Europe/London'},
	{label: '(GMT+01:00) Algiers', value: 'Africa/Algiers'},
	{label: '(GMT+01:00) Ceuta', value: 'Africa/Ceuta'},
	{label: '(GMT+01:00) Lagos', value: 'Africa/Lagos'},
	{label: '(GMT+01:00) Ndjamena', value: 'Africa/Ndjamena'},
	{label: '(GMT+01:00) Tunis', value: 'Africa/Tunis'},
	{label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek'},
	{label: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam'},
	{label: '(GMT+01:00) Andorra', value: 'Europe/Andorra'},
	{label: '(GMT+01:00) Central European Time - Belgrade', value: 'Europe/Belgrade'},
	{label: '(GMT+01:00) Berlin', value: 'Europe/Berlin'},
	{label: '(GMT+01:00) Brussels', value: 'Europe/Brussels'},
	{label: '(GMT+01:00) Budapest', value: 'Europe/Budapest'},
	{label: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen'},
	{label: '(GMT+01:00) Gibraltar', value: 'Europe/Gibraltar'},
	{label: '(GMT+01:00) Luxembourg', value: 'Europe/Luxembourg'},
	{label: '(GMT+01:00) Madrid', value: 'Europe/Madrid'},
	{label: '(GMT+01:00) Malta', value: 'Europe/Malta'},
	{label: '(GMT+01:00) Monaco', value: 'Europe/Monaco'},
	{label: '(GMT+01:00) Oslo', value: 'Europe/Oslo'},
	{label: '(GMT+01:00) Paris', value: 'Europe/Paris'},
	{label: '(GMT+01:00) Central European Time - Prague', value: 'Europe/Prague'},
	{label: '(GMT+01:00) Rome', value: 'Europe/Rome'},
	{label: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm'},
	{label: '(GMT+01:00) Tirane', value: 'Europe/Tirane'},
	{label: '(GMT+01:00) Vienna', value: 'Europe/Vienna'},
	{label: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw'},
	{label: '(GMT+01:00) Zurich', value: 'Europe/Zurich'},
	{label: '(GMT+02:00) Cairo', value: 'Africa/Cairo'},
	{label: '(GMT+02:00) Johannesburg', value: 'Africa/Johannesburg'},
	{label: '(GMT+02:00) Maputo', value: 'Africa/Maputo'},
	{label: '(GMT+02:00) Tripoli', value: 'Africa/Tripoli'},
	{label: '(GMT+02:00) Amman', value: 'Asia/Amman'},
	{label: '(GMT+02:00) Beirut', value: 'Asia/Beirut'},
	{label: '(GMT+02:00) Damascus', value: 'Asia/Damascus'},
	{label: '(GMT+02:00) Gaza', value: 'Asia/Gaza'},
	{label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem'},
	{label: '(GMT+02:00) Nicosia', value: 'Asia/Nicosia'},
	{label: '(GMT+02:00) Athens', value: 'Europe/Athens'},
	{label: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest'},
	{label: '(GMT+02:00) Chisinau', value: 'Europe/Chisinau'},
	{label: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki'},
	{label: '(GMT+02:00) Istanbul', value: 'Europe/Istanbul'},
	{label: '(GMT+02:00) Moscow-01 - Kaliningrad', value: 'Europe/Kaliningrad'},
	{label: '(GMT+02:00) Kiev', value: 'Europe/Kiev'},
	{label: '(GMT+02:00) Riga', value: 'Europe/Riga'},
	{label: '(GMT+02:00) Sofia', value: 'Europe/Sofia'},
	{label: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn'},
	{label: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius'},
	{label: '(GMT+03:00) Khartoum', value: 'Africa/Khartoum'},
	{label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi'},
	{label: '(GMT+03:00) Syowa', value: 'Antarctica/Syowa'},
	{label: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad'},
	{label: '(GMT+03:00) Qatar', value: 'Asia/Qatar'},
	{label: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh'},
	{label: '(GMT+03:00) Minsk', value: 'Europe/Minsk'},
	{label: '(GMT+03:00) Moscow+00 - Moscow', value: 'Europe/Moscow'},
	{label: '(GMT+03:30) Tehran', value: 'Asia/Tehran'},
	{label: '(GMT+04:00) Baku', value: 'Asia/Baku'},
	{label: '(GMT+04:00) Dubai', value: 'Asia/Dubai'},
	{label: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi'},
	{label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan'},
	{label: '(GMT+04:00) Moscow+01 - Samara', value: 'Europe/Samara'},
	{label: '(GMT+04:00) Mahe', value: 'Indian/Mahe'},
	{label: '(GMT+04:00) Mauritius', value: 'Indian/Mauritius'},
	{label: '(GMT+04:00) Reunion', value: 'Indian/Reunion'},
	{label: '(GMT+04:30) Kabul', value: 'Asia/Kabul'},
	{label: '(GMT+05:00) Mawson', value: 'Antarctica/Mawson'},
	{label: '(GMT+05:00) Aqtau', value: 'Asia/Aqtau'},
	{label: '(GMT+05:00) Aqtobe', value: 'Asia/Aqtobe'},
	{label: '(GMT+05:00) Ashgabat', value: 'Asia/Ashgabat'},
	{label: '(GMT+05:00) Dushanbe', value: 'Asia/Dushanbe'},
	{label: '(GMT+05:00) Karachi', value: 'Asia/Karachi'},
	{label: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent'},
	{label: '(GMT+05:00) Moscow+02 - Yekaterinburg', value: 'Asia/Yekaterinburg'},
	{label: '(GMT+05:00) Kerguelen', value: 'Indian/Kerguelen'},
	{label: '(GMT+05:00) Maldives', value: 'Indian/Maldives'},
	{label: '(GMT+05:30) India Standard Time', value: 'Asia/Calcutta'},
	{label: '(GMT+05:30) Colombo', value: 'Asia/Colombo'},
	{label: '(GMT+05:45) Katmandu', value: 'Asia/Katmandu'},
	{label: '(GMT+06:00) Vostok', value: 'Antarctica/Vostok'},
	{label: '(GMT+06:00) Almaty', value: 'Asia/Almaty'},
	{label: '(GMT+06:00) Bishkek', value: 'Asia/Bishkek'},
	{label: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka'},
	{label: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', value: 'Asia/Omsk'},
	{label: '(GMT+06:00) Thimphu', value: 'Asia/Thimphu'},
	{label: '(GMT+06:00) Chagos', value: 'Indian/Chagos'},
	{label: '(GMT+06:30) Rangoon', value: 'Asia/Rangoon'},
	{label: '(GMT+06:30) Cocos', value: 'Indian/Cocos'},
	{label: '(GMT+07:00) Davis', value: 'Antarctica/Davis'},
	{label: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok'},
	{label: '(GMT+07:00) Hovd', value: 'Asia/Hovd'},
	{label: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta'},
	{label: '(GMT+07:00) Moscow+04 - Krasnoyarsk', value: 'Asia/Krasnoyarsk'},
	{label: '(GMT+07:00) Hanoi', value: 'Asia/Saigon'},
	{label: '(GMT+07:00) Christmas', value: 'Indian/Christmas'},
	{label: '(GMT+08:00) Casey', value: 'Antarctica/Casey'},
	{label: '(GMT+08:00) Brunei', value: 'Asia/Brunei'},
	{label: '(GMT+08:00) Choibalsan', value: 'Asia/Choibalsan'},
	{label: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong'},
	{label: '(GMT+08:00) Moscow+05 - Irkutsk', value: 'Asia/Irkutsk'},
	{label: '(GMT+08:00) Kuala Lumpur', value: 'Asia/Kuala_Lumpur'},
	{label: '(GMT+08:00) Macau', value: 'Asia/Macau'},
	{label: '(GMT+08:00) Makassar', value: 'Asia/Makassar'},
	{label: '(GMT+08:00) Manila', value: 'Asia/Manila'},
	{label: '(GMT+08:00) China Time - Beijing', value: 'Asia/Shanghai'},
	{label: '(GMT+08:00) Singapore', value: 'Asia/Singapore'},
	{label: '(GMT+08:00) Taipei', value: 'Asia/Taipei'},
	{label: '(GMT+08:00) Ulaanbaatar', value: 'Asia/Ulaanbaatar'},
	{label: '(GMT+08:00) Western Time - Perth', value: 'Australia/Perth'},
	{label: '(GMT+08:30) Pyongyang', value: 'Asia/Pyongyang'},
	{label: '(GMT+09:00) Dili', value: 'Asia/Dili'},
	{label: '(GMT+09:00) Jayapura', value: 'Asia/Jayapura'},
	{label: '(GMT+09:00) Seoul', value: 'Asia/Seoul'},
	{label: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo'},
	{label: '(GMT+09:00) Moscow+06 - Yakutsk', value: 'Asia/Yakutsk'},
	{label: '(GMT+09:00) Palau', value: 'Pacific/Palau'},
	{label: '(GMT+10:30) Central Time - Adelaide', value: 'Australia/Adelaide'},
	{label: '(GMT+09:30) Central Time - Darwin', value: 'Australia/Darwin'},
	{label: "(GMT+10:00) Dumont D'Urville", value: 'Antarctica/DumontDUrville'},
	{label: '(GMT+10:00) Moscow+07 - Magadan', value: 'Asia/Magadan'},
	{label: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', value: 'Asia/Vladivostok'},
	{label: '(GMT+10:00) Eastern Time - Brisbane', value: 'Australia/Brisbane'},
	{label: '(GMT+11:00) Eastern Time - Hobart', value: 'Australia/Hobart'},
	{label: '(GMT+11:00) Eastern Time - Melbourne, Sydney', value: 'Australia/Sydney'},
	{label: '(GMT+10:00) Truk', value: 'Pacific/Chuuk'},
	{label: '(GMT+10:00) Guam', value: 'Pacific/Guam'},
	{label: '(GMT+10:00) Port Moresby', value: 'Pacific/Port_Moresby'},
	{label: '(GMT+11:00) Efate', value: 'Pacific/Efate'},
	{label: '(GMT+11:00) Guadalcanal', value: 'Pacific/Guadalcanal'},
	{label: '(GMT+11:00) Kosrae', value: 'Pacific/Kosrae'},
	{label: '(GMT+11:00) Norfolk', value: 'Pacific/Norfolk'},
	{label: '(GMT+11:00) Noumea', value: 'Pacific/Noumea'},
	{label: '(GMT+11:00) Ponape', value: 'Pacific/Pohnpei'},
	{label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', value: 'Asia/Kamchatka'},
	{label: '(GMT+13:00) Auckland', value: 'Pacific/Auckland'},
	{label: '(GMT+13:00) Fiji', value: 'Pacific/Fiji'},
	{label: '(GMT+12:00) Funafuti', value: 'Pacific/Funafuti'},
	{label: '(GMT+12:00) Kwajalein', value: 'Pacific/Kwajalein'},
	{label: '(GMT+12:00) Majuro', value: 'Pacific/Majuro'},
	{label: '(GMT+12:00) Nauru', value: 'Pacific/Nauru'},
	{label: '(GMT+12:00) Tarawa', value: 'Pacific/Tarawa'},
	{label: '(GMT+12:00) Wake', value: 'Pacific/Wake'},
	{label: '(GMT+12:00) Wallis', value: 'Pacific/Wallis'},
	{label: '(GMT+14:00) Apia', value: 'Pacific/Apia'},
	{label: '(GMT+13:00) Enderbury', value: 'Pacific/Enderbury'},
	{label: '(GMT+13:00) Fakaofo', value: 'Pacific/Fakaofo'},
	{label: '(GMT+13:00) Tongatapu', value: 'Pacific/Tongatapu'},
	{label: '(GMT+14:00) Kiritimati', value: 'Pacific/Kiritimati'},
];

export const categoryColors = {
	Projects: {light: '#b98ff9', dark: '#854FF0', darkest: '#5F2BE7'},
	People: {light: '#ffa39e', dark: '#FF7C75', darkest: '#FF3C35'},
	Business: {light: '#7fcdff', dark: '#48B8FF', darkest: '#1484FF'},
	Portfolio: {light: '#f1a8fe', dark: '#EA82FD', darkest: '#D642FB'},
};

export const PROJECT_STATUS = {
	OPPORTUNITY: 'OPPORTUNITY',
	PLANNING: 'PLANNING',
	RUNNING: 'RUNNING',
	HALTED: 'HALTED',
	DONE: 'DONE',
};

export const PASSWORD_VALIDATION_ERROR = {
	AT_LEAST_8_CHARS: 'AT_LEAST_8_CHARS',
	AT_MOST_64_CHARS: 'AT_MOST_64_CHARS',
	AT_LEAST_1_DIGIT: 'AT_LEAST_1_DIGIT',
	AT_LEAST_1_LETTER: 'AT_LEAST_1_LETTER',
	NO_IDENTICAL_3: 'NO_IDENTICAL_3',
	SAME_AS_OLD_PASSWORD: 'SAME_AS_OLD_PASSWORD',
};

export const LOGIN_TYPE = {
	MAIL: 'MAIL',
	ONELOGIN: 'ONELOGIN',
	OKTA: 'OKTA',
	GOOGLE: 'GOOGLE',
};

export const BUDGET_TYPE = {
	FIXED_PRICE: 'FIXED_PRICE',
	NON_BILLABLE: 'NON_BILLABLE',
	TIME_AND_MATERIALS: 'TIME_AND_MATERIALS',
	RETAINER: 'RETAINER',
	FIXED_PRICE_V2: 'FIXED_PRICE_V2',
};

export const PROGRAM_BUDGET_TYPE = {
	UNCAPPED: 'UNCAPPED',
	CAPPED: 'CAPPED',
	FIXED_PRICE: 'FIXED_PRICE',
};

export const PERIOD_BUDGET_TYPE = {
	FIXED_HOURS: 'FIXED_HOURS',
	FIXED_PRICE: 'FIXED_PRICE',
	TIME_AND_MATERIALS: 'TIME_AND_MATERIALS',
};

export const PHASE_BREAKDOWN_TYPE = {
	PHASE: 'PHASE',
	NO_PHASE: 'NO_PHASE',
	PROJECT_TIME_REG: 'PROJECT_TIME_REG',
	OFFSET: 'OFFSET',
	ACCUMULATED_TOTALS: 'ACCUMULATED_TOTALS',
};

export const ROLE_BREAKDOWN_TYPE = {
	ROLE: 'ROLE',
	NO_ROLE: 'NO_ROLE',
	EXPENSES: 'EXPENSES',
};

export const QUANTITY_LOCKED_REASON = {
	FIXED_PRICE: 'FIXED_PRICE',
	FIXED_PRICE_PERIOD: 'FIXED_PRICE_PERIOD',
};

export const STATUS_PROJECT_COLOR = {
	RED: 'RED',
	YELLOW: 'YELLOW',
	GREEN: 'GREEN',
};

export const RECENT_ACTIVITY = {
	TODAY: 'today',
	WEEK: 'week',
	MONTH: 'month',
};

export const FILE_DETAILS = {
	// Null is a wild card
	EXE: {magicNumber: [0x4d, 0x5a], mimeType: 'application/vnd.microsoft.portable-executable', extension: 'exe'},
	PNG: {magicNumber: [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], mimeType: 'image/png', extension: 'png'},
	GIF87a: {magicNumber: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61], mimeType: 'image/gif', extension: 'gif'},
	GIF89a: {magicNumber: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61], mimeType: 'image/gif', extension: 'gif'},
	JPG: {magicNumber: [0xff, 0xd8, 0xff], mimeType: 'image/jpeg', extension: 'jpg'},
	JPEG: {magicNumber: [0xff, 0xd8, 0xff], mimeType: 'image/jpeg', extension: 'jpeg'},
	JFIF: {magicNumber: [0xff, 0xd8, 0xff], mimeType: 'image/jpeg', extension: 'jfif'},
	SVG: {magicNumber: [], mimeType: 'image/svg+xml', extension: 'svg'},
};

/**
 * Maximum file size for preview -> 20MB
 * @type {number}
 */
export const MAX_PREVIEW_FILE_SIZE = 1048576 * 20;
/**
 * Maximum total preview size -> 20MB * 5 = 100MB
 * @type {number}
 */
export const MAX_TOTAL_PREVIEW_SIZE = MAX_PREVIEW_FILE_SIZE * 5;

export const LONGEST_MAGIC_NUMBER = 8;

export const FILE_BLACKLIST = [FILE_DETAILS.EXE];

export const ESTIMATION_UNIT = {
	HOURS: 'HOURS',
	POINTS: 'POINTS',
};

export const DATE_PICKER_STYLE = {
	TASK_MODAL_TASK_DATE: 0,
	COMPACT: 1,
	STANDARD: 2,
	SCOPING_PHASE_DATE: 3,
	INVOICE: 4,
	MODAL: 5,
	SLIM: 6,
	BASELINE_PHASE_DATE: 7,
};

export const INSIGHTS_NEW_BUDGET_COLUMNS = [
	'forecastMoney',
	'reportedMoney',
	'remainingMoney',
	'projectedMoney',
	'forecastCost',
	'forecastProfit',
	'projectedProfit',
];

export const MODULE_TYPES = {
	FINANCE: 'FINANCE',
	RETAINERS: 'RETAINERS',
	JIRA: 'JIRA',
	AZURE_DEVOPS: 'AZURE_DEVOPS',
	SALESFORCE: 'SALESFORCE',
	BASELINE: 'BASELINE',
	ONELOGIN: 'ONELOGIN',
	TIME_LOCKING: 'TIME_LOCKING',
	AZURE_ACTIVE_DIRECTORY: 'AZURE_ACTIVE_DIRECTORY',
	OKTA: 'OKTA',
	UNIT4: 'UNIT4',
	DEPARTMENTS: 'DEPARTMENTS',
	HUBSPOT: 'HUBSPOT',
	SAGE_INTACCT: 'SAGE_INTACCT',
	SAGE_INTACCT_RESTRICTED: 'SAGE_INTACCT_RESTRICTED',
	SAGE_INTACCT_RESTRICTED_PROJECT_FINANCIALS_ACCESS: 'SAGE_INTACCT_RESTRICTED_PROJECT_FINANCIALS_ACCESS',
	SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS: 'SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS',
	SAGE_INTACCT_RESTRICTED_FINANCE_MENU_ACCESS: 'SAGE_INTACCT_RESTRICTED_FINANCE_MENU_ACCESS',
	SAGE_INTACCT_RESTRICTED_REPORTING_ACCESS: 'SAGE_INTACCT_RESTRICTED_REPORTING_ACCESS',
	PIPEDRIVE: 'PIPEDRIVE',
	BAMBOOHR: 'BAMBOOHR',
	GO_SIMPLO: 'GO_SIMPLO',
	SISENSE: 'SISENSE',
	PERMISSION_MANAGEMENT: 'PERMISSION_MANAGEMENT',
	SKILLS: 'SKILLS',
	NOVA_INSIGHTS: 'NOVA_INSIGHTS',
	CUSTOM_FIELDS: 'CUSTOM_FIELDS',
	SKILL_LEVELS: 'SKILL_LEVELS',
	PLACEHOLDERS: 'PLACEHOLDERS',
	CAPACITY_OVERVIEW: 'CAPACITY_OVERVIEW',
	SOFT_ALLOCATIONS: 'SOFT_ALLOCATIONS',
	CALC_WIN_PERCENTAGE: 'CALC_WIN_PERCENTAGE',
	ALLOCATION_APPROVE: 'ALLOCATION_APPROVE',
	PROGRAMS: 'PROGRAMS',
	PROGRAMS_PLUS: 'PROGRAMS_PLUS',
	CONTRIBUTOR_PROFILE: 'CONTRIBUTOR_PROFILE',
	DATA_IMPORTER: 'DATA_IMPORTER',
	ASANA: 'ASANA',
};

export const TIERS = {
	TRIAL: 'TRIAL',
	LITE: 'LITE',
	PRO: 'PRO',
	CORE: 'CORE',
	ENTERPRISE: 'ENTERPRISE',
	FREE_FOREVER: 'FREE_FOREVER',
};

export const SEAT = {
	VIRTUAL: 'VIRTUAL',
	COLLABORATOR: 'COLLABORATOR',
	CORE: 'CORE',
	FULL: 'FULL',
};

export const ItemTypes = {
	BOX: 'box',
	PROJECT_TEAM_PERSON: 'project_team_person',
	PROJECT_PHASES_TASK: 'project_phases_task',
	PROJECT_BOARD: 'project_board',
	PROJECT_SETTINGS_STATUS: 'project_settings_status',
	PROJECT_TASK: 'project_task',
	PROJECT_FILE: 'project_file',
	INSIGHT_COMPONENT: 'insight_component',
	INSIGHT_COMPONENT_SMALL: 'small',
	INSIGHT_COMPONENT_MEDIUM: 'medium',
	INSIGHT_COMPONENT_LARGE: 'large',
	SUB_TASK: 'task_modal_sub_task',
};

export const WorkflowCategories = {
	TODO: 'TODO',
	INPROGRESS: 'INPROGRESS',
	DONE: 'DONE',
};

export const GroupProgressCategories = {
	PAST: 'PAST',
	ACTIVE: 'ACTIVE',
	FUTURE: 'FUTURE',
	NONE: 'NONE',
};

export const ProjectWinChance = {
	ZERO: 'ZERO',
	OVER_10: 'OVER_10',
	OVER_30: 'OVER_30',
	OVER_50: 'OVER_50',
	OVER_70: 'OVER_70',
	OVER_90: 'OVER_90',
	HUNDRED: 'HUNDRED',
};

export const ProjectAllocations = {
	CONTAINS_SOFT: 'CONTAINS_SOFT',
	CONTAINS_PLACEHOLDER: 'CONTAINS_PLACEHOLDER',
};

export const PersonAllocations = {
	CONTAINS_SOFT: 'CONTAINS_SOFT',
};

export const DependencyType = {
	CANNOT_START: 'CANNOT_START',
	CANNOT_BE_COMPLETED: 'CANNOT_BE_COMPLETED',
};

export const DeadlineFrom = {PROJECT: 'PROJECT', SPRINT: 'SPRINT', PHASE: 'PHASE', TASK: 'TASK'};

export const BUTTON_VARIANT = {
	PURPLE_FILLED: 'purple_filled',
	PURPLE_OUTLINE: 'purple_outline',
	GREEN_FILLED: 'green_filled',
	VERY_LIGHT_GRAY_OUTLINE: 'very_light_gray_outline',
	RED_FILLED: 'red_filled',
	RED_OUTLINE: 'red_outline',
};

export const BUTTON_STYLE = {
	FILLED: 'filled',
	OUTLINE: 'outline',
	OUTLINE_THICK: 'outline thick',
	TEXT: 'text',
};

export const BUTTON_COLOR = {
	VERYLIGHTGREY: 'very-lightgrey',
	LIGHTGREY: 'lightgrey',
	GREEN: 'green',
	GREY: 'grey',
	PURPLE: 'purple',
	FADEDGREY: 'fadedGrey',
	WHITE: 'white',
	WHITE_ACTIVE: 'white-active',
	RED: 'red',
	MEDIUMGREY: 'mediumgrey',
	AI_BLUE: 'ai-blue',
	TRANSPARENT: 'transparent',
	PINK: 'pink',
	LIGHT: 'light',
	GREEN_APPROVE: 'green-approve',
	MODERN_BLUE: 'modern-blue',
};

export const REPORT_GROUPINGS = {
	PERSON: 'person',
	PROJECT: 'project',
	TASK: 'task',
	CLIENT: 'client',
	PHASE: 'phase',
	SPRINT: 'spint',
	ROLE: 'role',
	TIME_REG: 'timeReg',
	DEPARTMENT: 'department',
};

export const PROJECT_TASK_REPORT_GROUPINGS = {
	PERSON: 'person',
	TASK: 'task',
	PHASE: 'phase',
	ROLE: 'role',
};

export const PERIOD_TYPE = {
	YEAR: 'year',
};
export const ELEMENT_TYPE = {
	FILTER_V4: 'filter_v4',
	BUTTON_TOGGLE_GROUP: 'button_toggle_group',
	CUSTOM_CONTENT: 'custom_content',
	BUTTON: 'button',
	INDICATOR: 'indicator',
	TOGGLE_SWITCH: 'toggle_switch',
	DROPDOWN: 'dropdown',
	LEFT_DROPDOWN: 'left_dropdown',
	DROPDOWN_PERSON: 'dropdown_person',
	LINK: 'link',
	BULK_EDIT: 'bulk_edit',
	THE_EYE: 'the_eye',
	FILTER: 'filter',
	SEARCH: 'search',
	SEARCH_LAZY: 'search_lazy',
	COLLAPSE: 'collapse',
	BURGER_MENU: 'burger_menu',
	DATE_CHANGER: 'date_changer',
	DATE_CHANGER_V2: 'date_changer_v2',
	DATE_PICKER: 'date_picker',
	PRESET_DATE_RANGE_PICKER: 'preset_date_range_picker',
	EMPTY_SPACE: 'empty_space',
	E_CONOMIC: 'e_conomic',
	CSV: 'csv',
	PDF: 'pdf',
	FILE_UPLOAD: 'file_upload',
	VIEW_TYPE_BUTTON: 'view_type_button',
	MEETING_CREATOR: 'meeting_creator',
	ZOOM_MENU: 'zoom_menu',
	EXPAND_ALL_CANVAS: 'expand_all_canvas',
	DEPENDENCY_MODE_SCHEDULING: 'dependency_mode_scheduling',
	HELP_LOGO: 'help_logo',
	UNIT_TOGGLE: 'unit_toggle',
	HEADER_LABEL: 'header_label',
	BETA_TOOLTIP: 'beta_tooltip',
	GIVE_FEEDBACK: 'give_feedback',
	INFO_TEXT: 'info_text',
	NESTED_DROPDOWN: 'nested_dropdown',
	BACK_BUTTON: 'back_button',
	ACTIONS_MENU: 'actions_menu',
	TOOLTIP_ICON: 'tooltip_icon',
	SEARCH_INPUT: 'search_input',
	FILTER_V3: 'filter_v3',
	DATE_RANGE_PICKER_WITH_QUICK_RANGES: 'date_range_picker_with_quick_ranges',
	HIDE_AND_SHOW: 'hide_and_show_a_section',
	TITLE: 'title',
	ONBOARDING_POPUP: 'onboarding_popup',
	PAGE_TITLE: 'page_title',
	INPUT_REPORT_TITLE: 'input_report_title',
	SIMPLE_TEXT_REPORT_TITLE: 'simple_text_report_title',
	YEAR_STEPPER: 'year_stepper',
	LAST_EDIT_INFO: 'last_edit_info',
	SHARE: 'share',
	PRINT: 'print',
	COPY_LINK: 'copy_link',
	WIN_PROBABILITY: 'win_probability',
	ALLOCATION_CONTROLS: 'allocation_controls',
	DROPDOWN_PHASES: 'dropdown_phases',
	ZEN_MODE: 'zen_mode',
	SKYLINE_BUTTON: 'skyline_button',
	SYSTEM_STATUS_MESSAGE: 'system_status_message',
};

export const TIME_VIEW = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
};

export const PERIODICITY = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	YEARLY: 'YEARLY',
};

export const SCHEDULING_VIEW = {
	PEOPLE: 'people',
	PROJECTS: 'projects',
	PLACEHOLDERS: 'demand',
	CAPACITY_OVERVIEW: 'capacity-overview',
};

/**
 * TODO - more like NOT TODO :
 * Do not use this one but the one from
 * the Empty_State component
 */
export const EMPTY_STATE = {
	SCOPING: 0,
	PHASE: 1,
	PROJECTS: 2,
	STARRED_TASKS: 3,
	NOTIFICATIONS: 4,
	RATE_CARDS_ADMIN: 5,
	INVOICES: 6,
	RETAINER: 7,
	FILES: 8,
	MY_TIMESHEETS: 9,
	SPRINTS: 10,
	SPRINTS_CHILDREN: 11,
	BUDGET: 12,
	ALL_TASKS: 13,
	RATE_CARDS_PROJECT: 14,
	PROJECTS_COLLABORATOR: 15,
	NO_RESULT_FOUND: 16,
	STARRED_TASKS_SMALL: 17,
};

export const SOCKET_EVENT_TYPE = {
	PROJECT_PERSON: 'PROJECT_PERSON',
	PERSON_NOTIFICATION: 'PERSON_NOTIFICATION',
	TASK: 'TASK',
	SUB_TASK: 'SUB_TASK',
	TIME_REG: 'TIME_REG',
	TIME_LOCK: 'TIME_LOCK',
	PHASE: 'PHASE',
	SPRINT: 'SPRINT',
	PERSON: 'PERSON',
	PROJECT: 'PROJECT',
	BASELINE_ITEM: 'BASELINE_ITEM',
	IDLE_TIME: 'IDLE_TIME',
	INSIGHT: 'INSIGHT',
	COMMENT: 'COMMENT',
	FILE: 'FILE',
	MODULE: 'MODULE',
	FEATURE_FLAG: 'FEATURE_FLAG',
	PERMISSION: 'PERMISSION',
	TIME_REGISTRATION_SETTINGS: 'TIME_REGISTRATION_SETTINGS',

	// Events from Project service
	PROGRAM: 'PROGRAM',
	V2_PROJECT: 'V2_PROJECT',

	// Events from Financial service
	PROGRAM_FINANCIALS: 'PROGRAM_FINANCIALS',
	PROJECT_FINANCIALS: 'PROJECT_FINANCIALS',
	TASK_FINANCIALS: 'TASK_FINANCIALS',
};

export const SOCKET_ACTION = {
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
};

export const TASK_COST_CALCULATION_TYPE = {
	FIXED_UNASSIGNED_TASKS: 'FIXED_UNASSIGNED_TASKS',
	PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS: 'PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS',
	COMPANY_AVERAGE_UNASSIGNED_TASKS: 'COMPANY_AVERAGE_UNASSIGNED_TASKS',
};
export const FILTER_GROUP = {
	PERSON: 'person',
	PROJECT: 'project',
	TASK: 'task',
	TIMEREGS: 'timeRegs',
	INVOICES: 'invoices',
	PLACEHOLDER: 'placeholder',
	RESOURCES: 'resources',
	EXPENSES: 'expenses',
};
export const STRING_COMPARE = {
	STARTS_WITH: 'startsWith',
	ENDS_WITH: 'endsWith',
	INCLUDES: 'includes',
};

export const REPORT_TYPE = {
	TIME_REG: 'Time Registered',
	PROJECT_PORTFOLIO_REPORT: 'Project Portfolio Report',
	UTILIZATION_REPORT: 'Utilization Report',
	TASK_REPORT: 'Task report',
};

export const FILTER_TYPE = {
	ALL: 'all',
	INDICATOR: 'indicator',
	INDICATOR_FILTERED: 'indicator_filtered',
	TASK_PROJECT_LABEL: 'taskProjectLabel',
	LABEL: 'label',
	LABELS: 'labels',
	LABEL_TIME_REG: 'labelTimeReg',
	RECENT_ACTIVITY: 'recentActivity',
	OWNER: 'owner',
	FOLLOWER: 'follower',
	PROJECT: 'project',
	CLIENT: 'client',
	ROLE: 'role',
	PROJECT_STAGE: 'projectStage',
	PROJECT_STATUS: 'projectStatus',
	PERSON: 'person',
	PROJECT_PERSON: 'projectPerson',
	PROJECT_PHASE: 'projectPhase',
	ALL_PROJECT_PHASES: 'allProjectPhases',
	PROJECT_SPRINT: 'projectSprint',
	PROJECT_OWNER: 'projectOwner',
	PROJECT_OWNER_TIME_REG_REPORT: 'projectOwnerTimRegReport',
	PROJECT_FOLLOWER: 'projectFollower',
	TEAM: 'team',
	STATUS_CATEGORY: 'statusCategory',
	PHASE_CATEGORY: 'phaseCategory',
	SPRINT_CATEGORY: 'sprintCategory',
	PERMISSION_LEVEL: 'permissionLevel',
	PROJECT_STATUS_COLUMN: 'projectStatusColumn',
	CONTACT: 'contact',
	CLIENT_GUEST_USERS: 'client_guest_users',
	SUB_TASKS: 'sub_tasks',
	TASK_HIERARCHY: 'task_hierarchy',
	DEADLINE: 'deadline',
	PERSON_SPRINT_WORKLOAD: 'personSprintWorkload',
	PERSON_ALLOCATED_IN_SPRINT_AND_PROJECT: 'personAllocatedInSprintAndProject',
	PERSON_PHASE_WORKLOAD: 'personPhaseWorkload',
	PERSON_ALLOCATED_IN_PHASE_AND_PROJECT: 'personAllocatedInPhaseAndProject',
	RATE_CARD: 'rateCard',
	PROJECT_TYPE: 'projectType',
	SPRINT_END_DATE: 'sprintEndDate',
	PROGRESS: 'progress',
	CAPACITY_WORKLOAD: 'capacityWorkload',
	PERSON_ALLOCATED_IN_PROJECT: 'personAllocatedInProject',
	TEAM_MEMBER: 'teamMember',
	PROJECT_AND_IDLE: 'projectAndIdle',
	PERSON_ALLOCATED_TO_PROJECT_AND_IDLE: 'personAllocatedToProjectAndIdle',
	DEPENDENCIES: 'dependencies',
	APPROUVED: 'approuved',
	APPROVAL_STATUS: 'approvalStatus',
	DEPARTMENT: 'department',
	INTERNAL_TIME: 'internalTime',
	TIME_OFF: 'timeOff',
	BILLABLE: 'billable',
	INVOICED: 'invoiced',
	PREDICTED_OVERRUN: 'predictedOverrun',
	INVOICE_STATUS: 'invoiceStatus',
	PAYMENT_STATUS: 'paymentStatus',
	TASK_LEVEL: 'taskLevel',
	SKILL_PERSON: 'skill_person',
	SKILL_ROLE: 'skill_role',
	SKILL_RESOURCE_TYPE: 'skill_resource_type',
	SKILL_TEAM: 'skill_team',
	SKILL_DEPARTMENT: 'skill_department',
	SKILL_ASSIGNED: 'skill_assigned',
	SKILL_LEVEL: 'skill_level',
	SKILL: 'skill',
	SKILL_AND: 'skill_and',
	LABEL_APPLIED: 'label_applied',
	LABEL_ALLOWED: 'label_allowed',
	PRIORITY_LEVEL: 'priorityLevel',
	PROJECT_GROUP: 'projectGroup',
	PROGRAM: 'program',
	PROJECT_WIN_CHANCE: 'project_win_chance',
	PROJECT_ALLOCATIONS: 'project_allocations',
	PERSON_ALLOCATIONS: 'person_allocations',
	DUE_DATE: 'due_date',
	QBO_STATUS: 'qbo_status',
	XERO_STATUS: 'xero_status',
	ECONOMIC_STATUS: 'economic_status',
	SAGE_INTACCT_STATUS: 'sage_intacct_status',
	BILL_FROM: 'bill_from',
	BILL_TO: 'bill_to',
	EXPENSE_CATEGORY: 'expense_category',
	EXPENSE_BILLING_OPTIONS: 'expense_billing_options',
};
export const GLOBAL_FILTER_OPERATOR = {
	IS: 'IS',
	IS_NOT: 'IS_NOT',
	IS_ALL: 'IS_ALL',
	IS_NOT_ALL: 'IS_NOT_ALL',
	CONTAINS: 'CONTAINS',
	CONTAINS_NOT: 'CONTAINS_NOT',
	GREATER: 'GREATER',
	GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
	LESS: 'LESS',
	LESS_OR_EQUAL: 'LESS_OR_EQUAL',
	IN: 'IN',
	NOT_IN: 'NOT_IN',
	ALL_IN: 'ALL_IN',
	NOT_ALL_IN: 'NOT_ALL_IN',
};
export const GLOBAL_FILTER_CONTEXT = {
	PROJECTS: 'PROJECTS',
	PEOPLE: 'PEOPLE',
	TASKS: 'TASKS',
	TIME_REGS: 'TIME_REGS',
	INVOICES: 'INVOICES',
	SKILLS: 'SKILLS',
	LABELS: 'LABELS',
	PLACEHOLDERS: 'PLACEHOLDERS',
	RESOURCES: 'RESOURCES',
};
export const GLOBAL_FILTER_FIELD = {
	COMPANY_TASK_ID: 'COMPANY_TASK_ID',
	NAME: 'NAME',
	STATUS: 'STATUS',
	PROJECT_STATUS: 'PROJECT_STATUS',
	PROJECT_GROUP_STATUS: 'PROJECT_GROUP_STATUS',
	PROJECT_PHASE: 'PROJECT_PHASE',
	PROJECT_SPRINT: 'PROJECT_SPRINT',
	PROJECT_GROUP_SPRINT: 'PROJECT_GROUP_SPRINT',
	PROJECT_FOLLOWER: 'PROJECT_FOLLOWER',
	DESCRIPTION: 'DESCRIPTION',
	REMAINING: 'REMAINING',
	TIME_REGS: 'TIME_REGS',
	FORECAST: 'FORECAST',
	TASK_APPROVED: 'TASK_APPROVED',
	START_DATE: 'START_DATE',
	START_DATE_PROJECT: 'START_DATE_PROJECT',
	END_DATE: 'END_DATE',
	END_DATE_PROJECT: 'END_DATE_PROJECT',
	START_DATE_TIME_REG: 'START_DATE_TIME_REG',
	END_DATE_TIME_REG: 'END_DATE_TIME_REG',
	PERSON_TIME_REG: 'PERSON_TIME_REG',
	PERSON_PROJECT: 'PERSON_PROJECT',
	PROJECT_TIME_REG: 'PROJECT_TIME_REG',
	NON_PROJECT_TIME_REG: 'NON_PROJECT_TIME_REG',
	NON_PHASE_TIME_REG: 'NON_PHASE_TIME_REG',
	NON_TASK_TIME_REG: 'NON_TASK_TIME_REG',
	NON_SPRINT_TIME_REG: 'NON_SPRINT_TIME_REG',
	NON_CLIENT_TIME_REG: 'NON_CLIENT_TIME_REG',
	NON_ROLE_TIME_REG: 'NON_ROLE_TIME_REG',
	TEAM_TIME_REG: 'TEAM_TIME_REG',
	SPRINT_TIME_REG: 'SPRINT_TIME_REG',
	TASK_TIME_REG: 'TASK_TIME_REG',
	DEPARTMENT_TIME_REG: 'DEPARTMENT_TIME_REG',
	NON_DEPARTMENT_TIME_REG: 'NON_DEPARTMENT_TIME_REG',
	CLIENT_TIME_REG: 'CLIENT_TIME_REG',
	PHASE_TIME_REG: 'PHASE_TIME_REG',
	ROLE_TIME_REG: 'ROLE_TIME_REG',
	BILLABLE_TIME_REG: 'BILLABLE_TIME_REG',
	INTERNAL_TIME_REG: 'INTERNAL_TIME_REG',
	TIME_OFF_TIME_REG: 'TIME_OFF_TIME_REG',
	TASK: 'TASK',
	CREATED_AT: 'CREATED_AT',
	UPDATED_AT: 'UPDATED_AT',
	SET_DONE_AT: 'SET_DONE_AT',
	ASSIGNED: 'ASSIGNED',
	ASSIGNED_SELF: 'ASSIGNED_SELF',
	OWNER: 'OWNER',
	PROJECT_OWNER: 'PROJECT_OWNER',
	FOLLOWER: 'FOLLOWER',
	LABELS: 'LABELS',
	LABEL_TIME_REG: 'LABEL_TIME_REG',
	PROJECT_LABELS: 'PROJECT_LABELS',
	ROLE: 'ROLE',
	PROJECT: 'PROJECT',
	CLIENT: 'CLIENT',
	NON_CLIENT: 'NON_CLIENT',
	PROJECT_PERSON: 'PROJECT_PERSON',
	PROJECT_STAGE: 'PROJECT_STAGE',
	PROJECT_BUDGET_TYPE: 'PROJECT_BUDGET_TYPE',
	BUG: 'BUG',
	BLOCKED: 'BLOCKED',
	BILLABLE: 'BILLABLE',
	HIGH_PRIORITY: 'HIGH_PRIORITY',
	STARRED: 'STARRED',
	RECENT_TIME: 'RECENT_TIME',
	PHASE_CATEGORY: 'PHASE_CATEGORY',
	SPRINT_CATEGORY: 'SPRINT_CATEGORY',
	DEADLINE: 'DEADLINE',
	TEAM_MEMBER: 'TEAM_MEMBER',
	TEAM: 'TEAM',
	PERMISSION_LEVEL: 'PERMISSION_LEVEL',
	CLIENT_GUEST_USERS: 'CLIENT_GUEST_USERS',
	DEPENDENCIES: 'DEPENDENCIES',
	SUB_TASKS: 'SUB_TASKS',
	TASK_HIERARCHY: 'TASK_HIERARCHY',
	DEPARTMENT: 'DEPARTMENT',
	PERSON: 'PERSON',
	WITH_TIME_REG: 'WITH_TIME_REG',
	WITH_TASKS: 'WITH_TASKS',
	NO_CONTACT: 'NO_CONTACT',
	APPROVAL_STATUS: 'APPROVAL_STATUS',
	INTERNAL_TIME: 'INTERNAL_TIME',
	TIME_OFF: 'TIME_OFF',
	RATE_CARD: 'RATE_CARD',
	CONTACT: 'CONTACT',
	INVOICED: 'INVOICED',
	TASK_LEVEL: 'TASK_LEVEL',
	SKILL: 'SKILL',
	SKILL_AND: 'SKILL_AND',
	PRIORITY_LEVEL: 'PRIORITY_LEVEL',
	PROJECT_GROUP: 'PROJECT_GROUP',
	PROGRAM: 'PROGRAM',
	RECENT_ACTIVITY: 'RECENT_ACTIVITY',
	FILTER_BY_TIME_REG_SETTINGS: 'FILTER_BY_TIME_REG_SETTINGS',
	BILL_TO: 'BILL_TO',
	BILL_FROM: 'BILL_FROM',
	PROJECT_MANUAL_PROGRESS: 'PROJECT_MANUAL_PROGRESS',
};
export const FILTER_FIELD_VALUES = {
	PARENT_TASK: 'PARENT_TASK',
	SUB_TASK: 'SUB_TASK',
};

export const FILTER_SECTION = {
	PROJECT: 'PROJECT',
	SPRINT: 'SPRINT',
	SPRINT_BACKLOG: 'SPRINT_BACKLOG',
	WORKFLOW: 'WORKFLOW',
	SCOPING: 'SCOPING',
	SCHEDULING_PEOPLE: 'SCHEDULING_PEOPLE',
	SCHEDULING_PROJECTS: 'SCHEDULING_PROJECTS',
	SCHEDULING_PLACEHOLDERS: 'SCHEDULING_PLACEHOLDERS',
	SCHEDULING_CAPACITY_OVERVIEW: 'SCHEDULING_CAPACITY_OVERVIEW',
	MY_TASKS: 'MY_TASKS',
	MY_WORK: 'MY_WORK',
	MY_TIMESHEETS: 'MY_TIMESHEETS',
	PROJECTS_OVERVIEW: 'PROJECTS_OVERVIEW',
	FINANCIAL_PORTFOLIO_REPORT: 'FINANCIAL_PORTFOLIO_REPORT',
	TEAM_TIMESHEET: 'TEAM_TIMESHEET',
	TIME_APPROVAL: 'TIME_APPROVAL',
	PROJECT_TIMELINE: 'PROJECT_TIMELINE',
	INVOICING_OVERVIEW: 'INVOICING_OVERVIEW',
	INVOICING_INVOICES: 'INVOICING_INVOICES',
	EXPENSE_MANAGEMENT: 'EXPENSE_MANAGEMENT',
	REPORTED_TIME_TABLE: 'REPORTED_TIME_TABLE',
	UTILIZATION_REPORT: 'UTILIZATION_REPORT',
	TASK_REPORT: 'TASK_REPORT',
	SKILLS: 'SKILLS',
	LABELS: 'LABELS',
};
export const INITIALS_SIZE = {
	EXTRA_SMALL: 'EXTRA_SMALL', // width 21px
	SMALL: 'SMALL', // width 23px
	MEDIUM: 'MEDIUM', // width 25px
	LARGE: 'LARGE', // width 34px
	EXTRA_LARGE: 'EXTRA_LARGE', // width 125px
	HUNDRED: 'HUNDRED', //height 100px
};

export const DATE_RANGE_QUICK_RANGE = {
	CURRENT_MONTH: 'current_month',
	CURRENT_YEAR: 'current_year',
	LAST_MONTH: 'last_month',
	LAST_30_DAYS: 'last_30_days',
	CUSTOM: 'custom',
};

export const APPROVAL_TYPE = {
	SUBMIT: 'SUBMIT',
	SUBMIT_TIME_OFF: 'SUBMIT_TIME_OFF',
	APPROVE: 'APPROVE',
	FORCE_APPROVE: 'FORCE_APPROVE',
	UNSUBMIT: 'UNSUBMIT',
	UNAPPROVE: 'UNAPPROVE',
};
export const APPROVAL_STATUS = {
	SUBMITTED: 'SUBMITTED',
	NOT_SUBMITTED: 'NOT_SUBMITTED',
	APPROVED: 'APPROVED',
};
export const INVOICE_STATUS = {
	DRAFT: 'DRAFT',
	APPROVED: 'APPROVED',
	SENT: 'SENT',
	EXPORTED: 'EXPORTED',
};
export const PAYMENT_STATUS = {
	UNPAID: 'UNPAID',
	PARTIALLY_PAID: 'PARTIALLY_PAID',
	PAID: 'PAID',
	OVERDUE: 'OVERDUE',
};
export const QBO_STATUS = {
	OPEN: 'QBO_OPEN',
	PAID: 'QBO_PAID',
	VOIDED: 'QBO_VOIDED',
	PARTIALLY_PAID: 'QBO_PARTIALLY_PAID',
	OVERDUE: 'QBO_OVERDUE',
	DEPOSITED: 'QBO_DEPOSITED',
};
export const XERO_STATUS = {
	PAID: 'XERO_PAID',
	VOIDED: 'XERO_VOIDED',
	DRAFT: 'XERO_DRAFT',
	SUBMITTED: 'XERO_SUBMITTED',
	DELETED: 'XERO_DELETED',
	AUTHORISED: 'XERO_AUTHORISED',
};
export const ECONOMIC_STATUS = {
	BOOKED: 'ECONOMIC_BOOKED',
	DRAFT: 'ECONOMIC_DRAFT',
	NOT_DUE: 'ECONOMIC_NOT_DUE',
	PAID: 'ECONOMIC_PAID',
	SENT: 'ECONOMIC_SENT',
	UNPAID: 'ECONOMIC_UNPAID',
};
export const SAGE_INTACCT_STATUS = {
	POSTED: 'SAGE_POSTED',
	DRAFT: 'SAGE_DRAFT',
	NOT_DUE: 'SAGE_NOT_DUE',
	PAID: 'SAGE_PAID',
	SENT: 'SAGE_SENT',
	UNPAID: 'SAGE_UNPAID',
	VOIDED: 'SAGE_VOIDED',
	PARTIALLY_PAID: 'SAGE_PARTIALLY_PAID',
	OVERDUE: 'SAGE_OVERDUE',
	SUBMITTED: 'SAGE_SUBMITTED',
	DELETED: 'SAGE_DELETED',
	AUTHORISED: 'SAGE_AUTHORISED',
};
export const EXPENSE_BILLING_OPTIONS = {
	NON_BILLABLE: 'NON_BILLABLE',
	BILLABLE: 'BILLABLE',
	BILLABLE_AS_PART_OF_FIXED: 'BILLABLE_AS_PART_OF_FIXED',
	BILLABLE_ON_TOP_OF_FIXED: 'BILLABLE_ON_TOP_OF_FIXED',
};
export const BUDGET_MONEY_VIEWS = {
	WORK_AND_EXPENSES: 'WORK_AND_EXPENSES',
	EXPENSES: 'EXPENSES',
	INVOICE_PLAN: 'INVOICE_PLAN',
};
export const BUDGET_VIEWS = {
	MONEY: 'MONEY',
	TIME: 'TIME',
};

export const SCHEDULING_ACTION_MENU_TYPE = {
	PERSON: 'PERSON',
	PROJECT: 'PROJECT',
	TEAM_MEMBERS: 'TEAM_MEMBERS',
	PLACEHOLDER: 'PLACEHOLDER',
	PROGRAM: 'PROGRAM',
};

export const SPECIAL_ROLE = {
	EXPENSE: Buffer.from('Role:-1').toString('base64'),
};

export const HIDDEN_FEATURES = {
	TIME_REGISTRATIONS: 'TIME_REGISTRATIONS',
	BUDGET_TYPES: 'BUDGET_TYPES',
	REVENUE: 'REVENUE',
	COST: 'COST',
	REVENUE_RECOGNITION: 'REVENUE_RECOGNITION',
	CLIENT: 'CLIENT',
	CREATE_EXPENSE: 'CREATE_EXPENSE',
	CREATE_INVOICE: 'CREATE_INVOICE',
	REPORTING: 'REPORTING',
};

export const STAGE_SORTING = {
	OPPORTUNITY: '0',
	PLANNING: '1',
	RUNNING: '2',
	HALTED: '3',
	DONE: '4',
};

export const STATUS_COLOR_DESCRIPTION = {
	'#33cc33': 'On track',
	'#ffc107': 'At risk',
	'#ff3300': 'Off track',
};

export const STATUS_COLOR_INT = {
	'#33cc33': '0',
	'#ffc107': '1',
	'#ff3300': '2',
};

export const PROGRAM_MEMBER_ROLES = {
	OWNER: 'OWNER',
};

export const CAP_SETTING = {
	PERMITTED_UNTIL_CAP: 'AUTOMATIC',
	ALWAYS_PERMITTED: 'ALWAYS_PERMITTED',
	ALWAYS_DISABLED: 'ALWAYS_LOCKED',
};

export const SYNCH_BASELINE_SETTING = {
	BASELINE: 'BASELINE',
	SCOPING: 'SCOPING',
};

export const emailRegEx = '[^@]+@[^@]+';

export const MAX_BULK_SELECTION = 100; // Max number of task you can select
export const MAX_SUBTASK_DEPTH = 3; // Max number of tasks that can be nested below each other

export const DATE_FORMAT_DAY = 'YYYY-MM-DD';

export const NO_ID = 'NONE';
