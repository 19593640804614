import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Dropdown from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import RadioButton from '../../../../components/new-ui/radio_button';
import InvoiceProjectTables from '../components/invoice_project_tables';
import InvoiceDetailsForm from '../components/invoice_details_form';
import {
	GeneralSettingsContainer,
	InvoiceSetting,
	InvoiceSettingTitle,
	BlankInvoiceSettingOptions,
	ClientTimespanContainer,
	ClientDropdownContainer,
} from '../../../../styles/v2/invoice_modals_v2_styled';
import {prepareProjectData} from '../../../../components/insights/invoicing/invoicing_data_prepare';
import DatePicker from '../../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {DATE_PICKER_STYLE, BUDGET_TYPE} from '../../../../constants';

const ProjectSelectionOrInvoiceSettings = ({
	clients,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	company,
	selectedProjectsIds,
	selectedClientId,
	isClientChangeable = true,
	isCreatingBlankInvoice,
	setSelectedProjectsIds,
	setSelectedClientId,
	setIsCreatingBlankInvoice,
	setInvoice,
	invoice,
	currenciesDropdownOptions,
	isEditing,
	clientProjects,
	setHasError,
	projectId,
	modalRefetch,
	isProjectInvoicing,
	project,
}) => {
	const [clientOptions, setClientOptions] = useState([]);
	const intl = useIntl();

	const projectData = prepareProjectData(
		clientProjects,
		company,
		company.currency,
		startDate,
		endDate,
		null,
		isProjectInvoicing
	);

	const handleProjectOptionClick = projectId => {
		if (selectedProjectsIds.includes(projectId)) {
			setSelectedProjectsIds(selectedProjectsIds.filter(id => id !== projectId));
		} else {
			setSelectedProjectsIds([...selectedProjectsIds, projectId]);
		}
	};

	const handleSelectAllClick = projectIds => {
		const allSelected = projectIds.every(projectId => selectedProjectsIds.includes(projectId));
		if (allSelected) {
			setSelectedProjectsIds(selectedProjectsIds.filter(id => !projectIds.includes(id)));
		} else {
			const unselectProjectIds = projectIds.filter(id => !selectedProjectsIds.includes(id));
			setSelectedProjectsIds([...selectedProjectsIds, ...unselectProjectIds]);
		}
	};

	useEffect(() => {
		if (!isCreatingBlankInvoice) {
			const hasError = !selectedClientId || !selectedProjectsIds.length;
			setHasError(hasError);
		}
	}, [isCreatingBlankInvoice, selectedProjectsIds, selectedClientId]);

	useEffect(() => {
		let clientsDropdownOptions = clients.edges
			.map(client => ({value: client.node.id, label: client.node.name}))
			.sort(function (a, b) {
				return a.label.localeCompare(b.label);
			});
		if (!isCreatingBlankInvoice) {
			clientsDropdownOptions.unshift({value: -1, label: intl.formatMessage({id: 'common.all_clients'})});
		}
		setClientOptions(clientsDropdownOptions);
	}, [isCreatingBlankInvoice]);

	useEffect(() => {
		let selectedProjects = [];
		if (!projectId && !isCreatingBlankInvoice) {
			let invoicableProjects = projectData.time_material.projects
				.concat(projectData.fixed_price.projects)
				.concat(projectData.retainer.projects);
			invoicableProjects.forEach(project => {
				if (project.client && project.client.id === selectedClientId) {
					selectedProjects.push(project.id);
				}
			});
			setSelectedProjectsIds(selectedProjects);
		}
	}, [selectedClientId, startDate, endDate]);

	const onClientChange = clientId => {
		setSelectedProjectsIds([]); //remove all previously selected projects when changing the client
		setSelectedClientId(clientId);
	};

	const handleDateRangeChange = (newStartDate, newEndDate) => {
		if (modalRefetch) {
			modalRefetch(
				{
					startYear: newStartDate.year(),
					startMonth: newStartDate.month() + 1,
					startDay: newStartDate.date(),
					endYear: newEndDate.year(),
					endMonth: newEndDate.month() + 1,
					endDay: newEndDate.date(),
				},
				null,
				{force: true}
			);
		}
		setStartDate(newStartDate);
		setEndDate(newEndDate);
	};

	return (
		<>
			<GeneralSettingsContainer>
				<InvoiceSetting>
					<InvoiceSettingTitle>{intl.formatMessage({id: 'invoicing.invoice_type_label'})}</InvoiceSettingTitle>
					<BlankInvoiceSettingOptions>
						<RadioButton
							text={intl.formatMessage({id: 'invoicing.invoice_type_option.billable_fixed_price'})}
							value={false}
							stateValue={isCreatingBlankInvoice}
							onSelected={() => {
								if (!selectedClientId) {
									setSelectedClientId(-1);
								}
								setIsCreatingBlankInvoice(false);
							}}
						/>
						<RadioButton
							text={intl.formatMessage({id: 'invoicing.invoice_type_option.manual'})}
							value={true}
							stateValue={isCreatingBlankInvoice}
							onSelected={() => {
								if (selectedClientId === -1) {
									setSelectedClientId(null);
								}
								setIsCreatingBlankInvoice(true);
							}}
						/>
					</BlankInvoiceSettingOptions>
				</InvoiceSetting>
				<ClientTimespanContainer>
					{(!isProjectInvoicing || project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS) && (
						<div>
							<InvoiceSettingTitle>{intl.formatMessage({id: 'date_span_picker.date_range'})}</InvoiceSettingTitle>
							<DatePicker
								isNewDateRange={true}
								startDate={startDate}
								endDate={endDate}
								handleDateRangeChange={handleDateRangeChange}
								colorInherited={true}
								compactShowYear={true}
								datePickerStyle={DATE_PICKER_STYLE.STANDARD}
								clearable={false}
							/>
						</div>
					)}
					{!isProjectInvoicing && (
						<ClientDropdownContainer>
							<Dropdown
								required={true}
								disabled={!isClientChangeable}
								onChange={option => {
									if (option.value !== selectedClientId) onClientChange(option.value);
								}}
								options={clientOptions}
								value={selectedClientId}
								label={intl.formatMessage({id: 'common.client'})}
								placeholder={intl.formatMessage({id: 'common.client'})}
								userpilot={'client-dropdown'}
							/>
						</ClientDropdownContainer>
					)}
				</ClientTimespanContainer>
			</GeneralSettingsContainer>
			{isCreatingBlankInvoice ? (
				<InvoiceDetailsForm
					isEditing={isEditing}
					setInvoice={setInvoice}
					invoice={invoice}
					currenciesDropdownOptions={currenciesDropdownOptions}
					isCreatingBlankInvoice={true}
					currency={company.currency}
					selectedProjectsIds={selectedProjectsIds}
					setHasError={setHasError}
				/>
			) : clientProjects && clientProjects.length ? (
				<InvoiceProjectTables
					selectedProjectsIds={selectedProjectsIds}
					onProjectSelect={projectId => handleProjectOptionClick(projectId)}
					onSelectAll={projectIds => handleSelectAllClick(projectIds)}
					projectData={projectData}
					isProjectInvoicing={isProjectInvoicing}
				/>
			) : null}
		</>
	);
};

export default ProjectSelectionOrInvoiceSettings;
