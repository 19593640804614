import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, INITIALS_SIZE, MODULE_TYPES} from '../../constants';
import UpdatePersonMutation from '../../mutations/update_person_mutation.modern';
import DeletePersonMutation from '../../mutations/delete_person_mutation';
import DeleteSisenseUserMutation from '../../mutations/DeleteSisenseUserMutation';
import ClipboardButton from 'react-clipboard.js';
import {FormattedMessage, injectIntl} from 'react-intl';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import InputField from '../../components/inputs/input_field';
import SelectInput from '../../components/inputs/select_input';
import Util from '../../forecast-app/shared/util/util';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Person from '../../forecast-app/shared/components/person/person';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../components/warning';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import HarvestIcon from '../../images/integrations/harvest-logo.png';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {versionManager} from '../../version_manager';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import {Button} from 'web-components';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import {
	getProfileName,
	getSelectableProfiles,
	hasPermission,
	personIsAdmin,
	personIsVirtualUser,
} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import SetBambooHRUserMutation from '../../mutations/SetBambooHRUserMutation';
import SetSlackUserMutation from '../../mutations/slack_integration/SetSlackUserMutation';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import ProfileSkills from './skills/ProfileSkills';
import {LabelDropdown} from '../../components/dropdowns/LabelDropdown/LabelDropdown.tsx';
import DirectApi, {profilePicSrc} from '../../directApi';
import CreateSlackUserVerificationKeyMutation from '../../mutations/slack_integration/CreateSlackUserVerificationKeyMutation';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import SettingsProfileSubNav from './SettingsProfileSubNav';
import TeamMemberStatusSection from './people/TeamMemberStatusSection';
import SettingsCalendarWrapper from './settingsCalendar/SettingsCalendarWrapper';

class SettingsProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name:
				this.props.person.firstName !== null && this.props.person.lastName !== null
					? this.props.person.firstName + ' ' + this.props.person.lastName
					: this.props.person.firstName !== null && this.props.person.lastName === null
					? this.props.person.firstName
					: '',
			email: this.props.person.email ? this.props.person.email : '',
			phone: this.props.person.phone || '',
			job_title: this.props.person.jobTitle || '',
			role: null,
			skill: [],
			permissions: null,
			showEmailValidationError: false,
			gearMenuExpanded: false,
			selectedLabels: this.props.person.personLabels.edges.map(personLabel => personLabel.node.label.id),
			working_hours: this.getWorkingHours(this.props.language),
			gitlab_loading: false,
			gitlab_server_loading: false,
			gitlab_users: [],
			gitlab_server_users: [],
			gitlab_search_string: '',
			gitlab_server_search_string: '',
			linked_gitlab_user: null,
			linked_gitlab_server_user: null,
			github_loading: false,
			github_users: [],
			github_search_string: '',
			harvest_loading: false,
			harvest_users_name: '',
			unit4_loading: false,
			unit4_users: [],
			unit4_user_options: [],
			pending_unit4_query: '',
			bambooHRUsers: [],
			bambooHRLoading: false,
			slackUsers: [],
			slackLoading: false,
			jira_cloud_loading: false,
			jira_server_loading: false,
			jira_cloud_users: [],
			jira_server_users: [],
			sageIntacctUsers: [],
			sageIntacctLoading: false,
			startDate: this.props.person.startDate,
			endDate: this.props.person.endDate,
		};
	}

	componentDidMount() {
		document.title = 'Details - My Profile - Forecast';
		// If oauth is true "click" the link slack user button
		if (this.props.oauthSlack) this.linkSlackUser();

		this.fetchHarvestUser();
		this.fetchGitLabUser();
		this.fetchGithubUser();
		this.fetchJiraCloudUsers();
		this.fetchJiraServerUsers();
		this.fetchBambooHRUsers();
		this.fetchSlackUsers();
		this.fetchSageIntacctUsers();
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (nextProps.language !== this.props.language) {
			this.setState({working_hours: this.getWorkingHours(nextProps.language)});
		}
		if (
			nextProps.showEmailValidationError !== this.props.showEmailValidationError &&
			this.props.emailValidationErrorMessage !== nextProps.emailValidationErrorMessage
		) {
			this.setState({showEmailValidationError: nextProps.showEmailValidationError});
		}
		if (this.props.person && nextProps.person && nextProps.person.email !== this.props.person.email) {
			this.setState({email: nextProps.person.email});
		}
		if (this.props.person && nextProps.person && nextProps.person.startDate !== this.props.person.startDate) {
			this.setState({startDate: nextProps.person.startDate});
		}
		if (this.props.person && nextProps.person && nextProps.person.endDate !== this.props.person.endDate) {
			this.setState({endDate: nextProps.person.endDate});
		}
	}

	getWorkingHours(language) {
		return language === 'ENGLISH_US'
			? [
					this.props.person.sunday / 60,
					this.props.person.monday / 60,
					this.props.person.tuesday / 60,
					this.props.person.wednesday / 60,
					this.props.person.thursday / 60,
					this.props.person.friday / 60,
					this.props.person.saturday / 60,
			  ]
			: [
					this.props.person.monday / 60,
					this.props.person.tuesday / 60,
					this.props.person.wednesday / 60,
					this.props.person.thursday / 60,
					this.props.person.friday / 60,
					this.props.person.saturday / 60,
					this.props.person.sunday / 60,
			  ];
	}

	showChangesSavedToast() {
		createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
	}

	handleChangeName(value) {
		this.setState({name: value});
	}

	checkEmailValidity(isValid) {
		this.setState({showEmailValidationError: !isValid});
	}

	handleBlurName() {
		const fullName = this.state.name.trim();

		let first;
		let last;
		if (fullName.indexOf(' ') <= 0) {
			first = fullName;
			last = '';
		} else {
			first = fullName.substr(0, fullName.indexOf(' ')).trim();
			last = fullName.substr(fullName.indexOf(' ') + 1).trim();
		}
		this.props.changeName(first, last);
	}

	handleChangeEmail(value) {
		this.setState({email: value});
	}

	handleBlurEmail(valid) {
		if (valid) {
			this.props.changeEmail(this.state.email);
		}
	}

	handleChangePhone(value) {
		this.setState({phone: value});
	}

	handleBlurPhone() {
		this.props.changePhone(this.state.phone);
	}

	handleSelectRoleChange(value) {
		this.props.changeRole(value ? value.value : null);
	}

	handleSelectProfileChange(value) {
		if (value.length === 0) return;
		this.props.changeProfile(value);
	}

	handleSelectDepartmentChange(value) {
		this.props.changeDepartment(value ? value.value : null);
	}

	handleSelectLanguageChange(value) {
		this.props.changeLanguage(value ? value.value : null);
	}

	changeActiveStatus() {
		const onSuccess = () => {
			if (this.props.person.client) {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings.client-user_deleted'})});
			}
			this.props.history.goBack();
		};
		if (this.props.person.client) {
			const callbackPositive = () => {
				Util.CommitMutation(
					DeletePersonMutation,
					{
						id: this.props.person.id,
						parentId: this.props.person.client.id,
					},
					onSuccess
				);
			};
			showModal({
				type: MODAL_TYPE.GENERIC_DELETION_WARNING,
				deleteCallback: callbackPositive,
			});
		} else {
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: this.props.person.id,
					active: !this.props.person.active,
				},
				onSuccess
			);
		}
	}

	changeProfilePicture() {
		const {formatMessage} = this.props.intl;
		if (
			this.file_input.files[0] !== undefined &&
			this.file_input.files[0] !== null &&
			(this.file_input.files[0].type === 'image/jpeg' ||
				this.file_input.files[0].type === 'image/gif' ||
				this.file_input.files[0].type === 'image/png')
		) {
			this.props.changeProfilePicture(this.file_input.files[0]);
		} else {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div className="default-warning-modal">
						<Warning messageId="warning_modal.profile_picture_wrong_format_title" />
						<div className="warning-part-2">
							{formatMessage({id: 'warning_modal.profile_picture_wrong_format_info'})}
						</div>
					</div>
				),
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
		}
	}

	deleteProfilePicture() {
		this.props.deleteProfilePicture();
	}

	closeGearMenu() {
		this.setState({gearMenuExpanded: false});
	}

	toggleGearMenu() {
		this.setState({gearMenuExpanded: !this.state.gearMenuExpanded});
	}

	updateLabels = updatedLabels => {
		this.setState({selectedLabels: updatedLabels});
		if (this.props.isViewer) {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					labels: updatedLabels,
				},
				() => this.showChangesSavedToast()
			);
		} else {
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: this.props.person.id,
					labels: updatedLabels,
				},
				() => this.showChangesSavedToast()
			);
		}
	};

	saveWorkingHours(i, e) {
		const newHours = this.state.working_hours.slice();
		if (newHours[i] === e) return; //Don't trigger if the value did not change
		newHours[i] = e;

		const validateHours = val => {
			if (isNaN(val)) return false;
			if (parseFloat(val) < -600000) return false;
			else if (parseFloat(val) > 600000) return false;
			return true;
		};

		let monday, tuesday, wednesday, thursday, friday, saturday, sunday;
		if (this.props.viewer.language === 'ENGLISH_US') {
			sunday = newHours[0];
			monday = newHours[1];
			tuesday = newHours[2];
			wednesday = newHours[3];
			thursday = newHours[4];
			friday = newHours[5];
			saturday = newHours[6];
		} else {
			monday = newHours[0];
			tuesday = newHours[1];
			wednesday = newHours[2];
			thursday = newHours[3];
			friday = newHours[4];
			saturday = newHours[5];
			sunday = newHours[6];
		}
		let isValid = true;
		[monday, tuesday, wednesday, thursday, friday, saturday, sunday].forEach(val => {
			if (!validateHours(val)) {
				isValid = false;
			}
		});

		const working_hours = [monday, tuesday, wednesday, thursday, friday, saturday];
		if (this.props.language === 'ENGLISH_US') {
			working_hours.splice(0, 0, sunday);
		} else {
			working_hours.push(sunday);
		}
		this.setState({working_hours});
		if (!isValid) return;
		if (this.props.viewUser) {
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: this.props.person.id,
					monday: Math.round(monday * 60),
					tuesday: Math.round(tuesday * 60),
					wednesday: Math.round(wednesday * 60),
					thursday: Math.round(thursday * 60),
					friday: Math.round(friday * 60),
					saturday: Math.round(saturday * 60),
					sunday: Math.round(sunday * 60),
				},
				() => this.showChangesSavedToast()
			);
		} else {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					monday: Math.round(monday * 60),
					tuesday: Math.round(tuesday * 60),
					wednesday: Math.round(wednesday * 60),
					thursday: Math.round(thursday * 60),
					friday: Math.round(friday * 60),
					saturday: Math.round(saturday * 60),
					sunday: Math.round(sunday * 60),
				},
				() => this.showChangesSavedToast()
			);
		}
		this.setState({working_hours: newHours});
	}

	handleGitlabSearchChange(server, searchString) {
		if (server) {
			this.setState({gitlab_server_search_string: searchString || ''});
		} else {
			this.setState({gitlab_search_string: searchString || ''});
		}
	}

	handleGithubSearchChange(searchString) {
		this.setState({github_search_string: searchString || ''});
	}

	fetchGitLabUsers(server) {
		this.setState({gitlab_loading: true});
		DirectApi.Fetch(
			`gitlab_users?server=${server}&searchString=${
				server ? this.state.gitlab_server_search_string : this.state.gitlab_search_string
			}`
		)
			.then(json => {
				if (server) {
					this.setState({gitlab_server_loading: false, gitlab_server_users: json || []});
				} else {
					this.setState({gitlab_loading: false, gitlab_users: json || []});
				}
			})
			.catch(e => {
				this.setState({gitlab_server_loading: false, gitlab_loading: false});
			});
	}

	fetchUnit4Resources(filterString) {
		if (this.props.company.unit4Enabled) {
			if (this.state.unit4_loading) {
				// Users already being fetched, store query for later
				this.setState({pending_unit4_query: filterString});
			} else {
				this.setState({unit4_loading: true, pending_unit4_query: ''});

				DirectApi.Fetch(`unit4_users?filterString=${filterString}`).then(users => {
					const options = users
						.filter(u => !u.forecastId)
						.sort((a, b) => {
							const userA = a.firstName.toUpperCase().trim() + ' ' + a.surname.toUpperCase().trim();
							const userB = b.firstName.toUpperCase().trim() + ' ' + b.surname.toUpperCase().trim();
							if (userA > userB) {
								return 1;
							} else if (userA < userB) {
								return -1;
							}
							return 0;
						})
						.map(u => ({value: u.personId, label: u.firstName.trim() + ' ' + u.surname.trim()}));
					this.setState({
						unit4_users: users,
						unit4_user_options: options,
						unit4_loading: false,
					});
					if (this.state.pending_unit4_query) {
						// Query updated while fetching, fetch again with updated query.
						this.fetchUnit4Resources(this.state.pending_unit4_query);
					}
				});
			}
		}
	}

	fetchBambooHRUsers() {
		if (this.props.company.bambooHREnabled) {
			this.setState({bambooHRLoading: true});

			let personId = this.props.person.actualPersonId ?? this.props.person.id;

			DirectApi.Fetch(`bamboohr/employees?personId=${Util.getIdFromBase64String(personId)}`).then(users => {
				this.setState({
					bambooHRUsers: users
						.map(u => ({value: u.id, label: `${u.firstName.trim()} ${u.lastName.trim()} (${u.workEmail})`}))
						.sort((u1, u2) => (u1.label < u2.label ? -1 : 1)),
					bambooHRLoading: false,
				});
			});
		}
	}

	fetchSlackUsers() {
		if (this.props.company.newSlackEnabled && this.props.person.slackId) {
			this.setState({slackLoading: true});

			let personId = this.props.person.actualPersonId ?? this.props.person.id;

			DirectApi.Fetch(`slack/users/${Util.getIdFromBase64String(personId)}`).then(users => {
				this.setState({
					slackUsers: users
						.map(u => ({
							value: u.id,
							label: `${u.profile.first_name.trim()} ${u.profile.last_name.trim()}`,
						}))
						.sort((u1, u2) => (u1.label < u2.label ? -1 : 1)),
					slackLoading: false,
				});
			});
		}
	}

	fetchSageIntacctUsers() {
		if (this.props.company.sageIntacctConnectionEnabled && !this.props.person.sageIntacctId) {
			this.setState({sageIntacctLoading: true});
			DirectApi.Fetch('sage_intacct/employees').then(res => {
				if (res?.employees) {
					this.setState({
						sageIntacctUsers: res.employees
							.map(u => ({
								value: u.EMPLOYEEID,
								label: `${u.PERSONALINFO__2e__FIRSTNAME} ${u.PERSONALINFO__2e__LASTNAME} (${u.PERSONALINFO__2e__EMAIL1})`,
								name: `${u.PERSONALINFO__2e__FIRSTNAME} ${u.PERSONALINFO__2e__LASTNAME}`,
								email: u.PERSONALINFO__2e__EMAIL1,
								location: u.LOCATIONID,
							}))
							.sort((a, b) => (a.label.toUpperCase().trim() > b.label.toUpperCase().trim() ? 1 : -1)),
						sageIntacctLoading: false,
					});
				} else {
					this.setState({
						sageIntacctUsers: [],
						sageIntacctLoading: false,
					});
				}
			});
		}
	}

	fetchHarvestUser() {
		if (this.props.company.harvestEnabled && this.props.person.harvestUser) {
			this.setState({harvest_loading: true});

			let param;
			if (this.props.isViewer) {
				param = this.props.person.actualPersonId;
			} else {
				param = this.props.person.id;
			}

			DirectApi.Fetch(`harvest_users?personId=${param}`).then(json => {
				this.setState({harvest_users_name: json.user, harvest_loading: false});
			});
		} else if (this.props.company.harvestEnabled) {
			this.setState({harvest_users_name: 'User is not linked to a Harvest user'});
		}
	}

	fetchJiraCloudUsers() {
		if (this.props.company.jiraCloudEnabled) {
			this.setState({jira_cloud_loading: true});

			DirectApi.Fetch(`jira/cloud/users/active`)
				.then(json => {
					this.setState({
						jira_cloud_loading: false,
						jira_cloud_users:
							json.map(user => ({
								linked: user.linked,
								value: user.accountId,
								label: `${user.displayName} (${user.emailAddress ? user.emailAddress : 'No Email'})`,
							})) || [],
					});
				})
				.catch(e => {
					this.setState({
						jira_cloud_loading: false,
						jira_cloud_users: [],
					});
				});
		}
	}

	fetchJiraServerUsers() {
		if (this.props.company.jiraServerEnabled) {
			this.setState({jira_server_loading: true});

			DirectApi.Fetch(`jira/server/users/active`)
				.then(json => {
					this.setState({
						jira_server_loading: false,
						jira_server_users:
							json.map(user => ({
								linked: user.linked,
								value: user.key,
								label: `${user.displayName} (${user.emailAddress ? user.emailAddress : 'No Email'})`,
							})) || [],
					});
				})
				.catch(e => {
					this.setState({
						jira_server_loading: false,
						jira_server_users: [],
					});
				});
		}
	}

	fetchGithubUsers() {
		if (this.props.company.githubEnabled) {
			this.setState({github_loading: true});

			DirectApi.Fetch(`github_users?searchString=${this.state.github_search_string}`)
				.then(json => {
					this.setState({github_loading: false, github_users: json || []});
				})
				.catch(e => {
					this.setState({github_loading: false, github_users: []});
				});
		}
	}

	fetchGitLabUser() {
		if (this.props.company.gitlabEnabled && this.props.person.gitlabUser) {
			const id = this.props.person.gitlabUser.integrationUserId;
			if (id) {
				DirectApi.Fetch(`gitlab_users?server=false&id=${id}`).then(json => {
					this.setState({linked_gitlab_user: json});
				});
			}
		}
		if (this.props.company.gitlabServerEnabled && this.props.person.gitlabServerUser) {
			const id = this.props.person.gitlabServerUser.integrationUserId;
			if (id) {
				DirectApi.Fetch(`gitlab_users?server=true&id=${id}`).then(json => {
					this.setState({linked_gitlab_server_user: json});
				});
			}
		}
	}

	fetchGithubUser() {
		if (this.props.company.githubEnabled && this.props.person.githubUser) {
			const id = this.props.person.githubUser.integrationUserId;
			if (id) {
				DirectApi.Fetch(`github_users?&id=${id}`)
					.then(json => {
						this.setState({linked_github_user: json});
					})
					.catch(e => {
						this.setState({linked_github_user: null});
					});
			}
		}
	}

	selectGitlabUser(u, server) {
		const {formatMessage} = this.props.intl;
		const imageSrc = profilePicSrc(this.props.person.profilePictureId);
		const onSuccess = () => {
			if (server) {
				this.setState({linked_gitlab_server_user: u});
			} else {
				this.setState({linked_gitlab_user: u});
			}
		};
		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.link_users',
			warningInformation: [
				<div className="gitlab-modal-content">
					<div>
						<FormattedMessage id="settings_profile.integration_user_link" />
					</div>
					<Person
						name={this.state.name}
						showName={true}
						role={this.state.email}
						showRole={true}
						imageSrc={imageSrc}
						imageSize="medium"
					/>
					<div>
						<FormattedMessage id="settings_profile.gitlab_link2" />
					</div>
					<div className="gitlab-user">
						<img height="24" width="24" src={u.avatar_url} />
						<div className="name">
							<span>{u.name}</span>
							<span className="username">{'@' + u.username}</span>
						</div>
					</div>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.link'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: this.updateGitlabUser.bind(this, u.id, server, onSuccess),
				},
			],
		});
	}

	selectGithubUser(u) {
		const {formatMessage} = this.props.intl;
		const imageSrc = profilePicSrc(this.props.person.profilePictureId);
		const onSuccess = () => {
			this.setState({linked_github_user: u});
		};
		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.link_users',
			warningInformation: [
				<div className="github-modal-content">
					<div>
						<FormattedMessage id="settings_profile.integration_user_link" />
					</div>
					<Person
						name={this.state.name}
						showName={true}
						role={this.state.email}
						showRole={true}
						imageSrc={imageSrc}
						imageSize="medium"
					/>
					<div>
						<FormattedMessage id="settings_profile.github_link2" />
					</div>
					<div className="github-user">
						<img height="24" width="24" src={u.avatar_url} alt={''} />
						<div className="name">
							<span>{u.username}</span>
							<span className="username">{'@' + u.username}</span>
						</div>
					</div>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.link'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: this.updateGithubUser.bind(this, u.id, onSuccess),
				},
			],
		});
	}

	removeGitLabUser(server) {
		const {formatMessage} = this.props.intl;
		const onSuccess = () => {
			if (server) {
				this.setState({linked_gitlab_server_user: null});
			} else {
				this.setState({linked_gitlab_user: null});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.gitlab_unlink',
			warningInformation: [formatMessage({id: 'settings_profile.gitlab_unlink_modal_description'})],
			useInfoIcon: true,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: this.updateGitlabUser.bind(this, 0, server, onSuccess),
				},
			],
		});
	}

	removeGithubUser(server) {
		const {formatMessage} = this.props.intl;
		const onSuccess = () => {
			this.setState({linked_github_user: null});
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.github_unlink',
			warningInformation: [formatMessage({id: 'settings_profile.github_unlink_modal_description'})],
			useInfoIcon: true,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: this.updateGithubUser.bind(this, 0, server, onSuccess),
				},
			],
		});
	}

	updateGitlabUser(userId, server, onSuccess) {
		if (this.props.viewUser) {
			const object = {
				id: this.props.person.id,
				gitlabPersonId: server ? undefined : userId,
				gitlabServerPersonId: server ? userId : undefined,
			};
			Util.CommitMutation(UpdatePersonMutation, object, onSuccess);
		} else {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					gitlabPersonId: server ? undefined : userId,
					gitlabServerPersonId: server ? userId : undefined,
				},
				onSuccess
			);
		}
	}

	updateGithubUser(userId, onSuccess) {
		if (this.props.viewUser) {
			const object = {
				id: this.props.person.id,
				githubPersonId: userId,
			};
			Util.CommitMutation(UpdatePersonMutation, object, onSuccess);
		} else {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					githubPersonId: userId,
				},
				onSuccess
			);
		}
	}

	unlinkUnit4User() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings_profile.unit4_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						if (this.props.viewUser) {
							Util.CommitMutation(UpdatePersonMutation, {
								id: this.props.person.id,
								unit4PersonId: null,
							});
						} else {
							Util.CommitMutation(UpdateViewerMutation, {
								unit4PersonId: null,
							});
						}
					},
				},
			],
		});
	}

	linkUnit4User(option) {
		const imageSrc = profilePicSrc(this.props.person.profilePictureId);
		const callback = () => {
			if (option) {
				if (this.props.viewUser) {
					Util.CommitMutation(UpdatePersonMutation, {
						id: this.props.person.id,
						unit4PersonId: option.value,
					});
				} else {
					Util.CommitMutation(UpdateViewerMutation, {
						unit4PersonId: option.value,
					});
				}
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.link_users',
			warningInformation: [
				<div className="unit4-modal-content">
					<div>
						<FormattedMessage id="settings_profile.integration_user_link" />
					</div>
					<Person
						name={this.state.name}
						showName={true}
						role={this.state.email}
						showRole={true}
						imageSrc={imageSrc}
						imageSize="medium"
					/>
					<div>
						<FormattedMessage id="settings_profile.unit4_link" />
					</div>
					<div className="unit4-user">{option.label}</div>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	setBambooHRUser(input) {
		let personId = this.props.person.actualPersonId ?? this.props.person.id;

		if (input) {
			// Linking
			Util.CommitMutation(SetBambooHRUserMutation, {forecastPersonId: personId, bambooHRUserId: input.value});
		} else {
			// Unlinking
			const {formatMessage} = this.props.intl;

			showModal({
				type: MODAL_TYPE.GENERIC,
				className: 'default-warning-modal',
				content: <Warning messageId="settings_profile.bamboohr_unlink" />,
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.unlink'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: () =>
							Util.CommitMutation(SetBambooHRUserMutation, {forecastPersonId: personId, bambooHRUserId: null}),
					},
				],
			});
		}
	}

	setSageIntacctUser(input) {
		const data = {
			sageIntacctId: input?.value ? input?.value : null,
			sageIntacctName: input?.name ? input?.name : null,
			sageIntacctEmail: input?.email ? input?.email : null,
			sageIntacctLocationId: input?.location ? input?.location : null,
		};
		if (this.props.viewUser) {
			data.id = this.props.person.id;
		}
		const mutation = this.props.viewUser ? UpdatePersonMutation : UpdateViewerMutation;

		if (input) {
			Util.CommitMutation(mutation, data, null, true);
		} else {
			const {formatMessage} = this.props.intl;
			showModal({
				type: MODAL_TYPE.GENERIC,
				className: 'default-warning-modal',
				content: <Warning message="Are you sure you want to unlink this user from Sage Intacct?" />,
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.unlink'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: () => Util.CommitMutation(mutation, data, null, true),
					},
				],
			});
		}
	}

	unlinkSlackUser() {
		let personId = this.props.person.actualPersonId ?? this.props.person.id;
		// Unlinking
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings_profile.slack_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => Util.CommitMutation(SetSlackUserMutation, {personId: personId, slackId: null}),
				},
			],
		});
	}

	linkSlackUser() {
		const redirectUri = `${process.env.API_URL}/slack/oauth_user`;
		const onSuccess = ({createSlackUserVerificationKey}) => {
			const scopes = ['channels:read', 'groups:read'];
			window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${
				process.env.SLACK_APP_CLIENT_ID
			}&redirect_uri=${redirectUri}&scope=&user_scope=${scopes.join(',')}&state=${createSlackUserVerificationKey.key}`;
		};

		Util.CommitMutation(CreateSlackUserVerificationKeyMutation, null, onSuccess);
	}

	unlinkJiraUser(type) {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings_profile.jira_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						const data = type === 'cloud' ? {jiraCloudId: null} : {jiraServerId: null};
						if (this.props.viewUser) {
							data.id = this.props.person.id;
							Util.CommitMutation(UpdatePersonMutation, data);
						} else {
							Util.CommitMutation(UpdateViewerMutation, data);
						}
					},
				},
			],
		});
	}

	linkJiraUser(option, type) {
		const imageSrc = profilePicSrc(this.props.person.profilePictureId);
		const callback = () => {
			if (option) {
				const data = type === 'cloud' ? {jiraCloudId: option.value} : {jiraServerId: option.value};
				if (this.props.viewUser) {
					data.id = this.props.person.id;
					Util.CommitMutation(UpdatePersonMutation, data);
				} else {
					Util.CommitMutation(UpdateViewerMutation, data);
				}
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings_profile.link_users',
			warningInformation: [
				<div className="jira-modal-content">
					<div>
						<FormattedMessage id="settings_profile.integration_user_link" />
					</div>
					<Person
						name={this.state.name}
						showName={true}
						role={this.state.email}
						showRole={true}
						imageSrc={imageSrc}
						imageSize="medium"
					/>
					<div>
						<FormattedMessage id="settings_profile.jira_link" />
					</div>
					<div className="jira-user">{option.label}</div>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	handleHolidayCalendarChange(value) {
		this.props.changeHolidayCalendar(value ? value.value : null);
	}

	handleChangeJobTitle(value) {
		this.setState({job_title: value});
	}

	handleBlurTitle() {
		this.props.changeJobTitle(this.state.job_title);
	}

	handleStartDateChange(value) {
		this.updateStartDate(value.format('YYYY-MM-DD'));
	}

	handleEndDateChange(value) {
		this.updateEndDate(value.format('YYYY-MM-DD'));
	}

	handleClearStartDate() {
		this.updateStartDate(null);
	}

	handleClearEndDate() {
		this.updateEndDate(null);
	}

	updateStartDate(startDate) {
		const onSuccess = () => {
			this.showChangesSavedToast();
		};
		if (this.props.isViewer) {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					startDate: startDate,
				},
				onSuccess
			);
		} else {
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: this.props.person.id,
					startDate: startDate,
				},
				onSuccess
			);
		}
	}

	updateEndDate(endDate) {
		const onSuccess = () => {
			this.showChangesSavedToast();
		};
		if (this.props.isViewer) {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					endDate: endDate,
				},
				onSuccess
			);
		} else {
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: this.props.person.id,
					endDate: endDate,
				},
				onSuccess
			);
		}
	}

	getTopHeader() {
		const {formatMessage} = this.props.intl;
		const content = [];
		if (this.props.viewUser) {
			content.push({type: TopHeaderBar.TYPE.BACK_BUTTON, history: this.props.history});
		}
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar
					title={
						this.props.viewUser
							? this.props.person.client
								? formatMessage({id: 'settings_profile.client-user-profile'})
								: formatMessage({id: 'settings_profile.team-member-profile'})
							: 'Account Configuration'
					}
					content={content}
				/>
			</TopHeaderBarWrapper>
		);
	}

	deleteSisenseUser() {
		const callbackPositive = () => {
			const onSuccess = res => {
				const {errors} = res.deleteSisenseUser;
				if (errors?.length) {
					if (errors[0] === 'PERSON_OWNS_DASHBOARDS') {
						showModal({
							type: MODAL_TYPE.WARNING,
							warningMessageId: 'sisense.person_owns_dashboards.title',
							warningInformation: [<FormattedMessage id="sisense.person_owns_dashboards.message" />],
							useInfoIcon: true,
							buttons: [
								{
									text: this.props.intl.formatMessage({id: 'common.ok'}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
								},
							],
						});
					} else {
						createToast({duration: 2000, message: 'Error deleting Advanced Analytics user'});
					}
				} else {
					createToast({duration: 2000, message: 'Advanced Analytics user deleted successfully'});
				}
			};

			Util.CommitMutation(
				DeleteSisenseUserMutation,
				{
					personId: this.props.person.actualPersonId ?? this.props.person.id,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'warning-modal.edit-warning-title',
			warningInformation: [<FormattedMessage id="sisense.delete_user.message" />],
			useInfoIcon: true,
			buttons: [
				{
					text: this.props.intl.formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
				{
					text: this.props.intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	}

	linkAdoUser() {
		showModal({
			type: MODAL_TYPE.ADO_LINK_USER,
			isViewer: this.props.isViewer,
			personId: this.props.person.id,
		});
	}

	unlinkAdoUser() {
		showModal({
			type: MODAL_TYPE.WARNING,
			message: `Are you sure you want to unlink ${this.props.person.firstName}${
				this.props.person.lastName ? ` ${this.props.person.lastName}` : ''
			} from Azure DevOps?`,
			useInfoIcon: true,
			buttons: [
				{
					text: this.props.intl.formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						const onSuccess = () => {
							createToast({duration: 2000, message: 'User unlinked from Azure DevOps'});
						};
						if (this.props.isViewer) {
							Util.CommitMutation(
								UpdateViewerMutation,
								{
									adoUserId: '',
								},
								onSuccess
							);
						} else {
							Util.CommitMutation(
								UpdatePersonMutation,
								{
									id: this.props.person.id,
									adoUserId: '',
								},
								onSuccess
							);
						}
					},
				},
				{
					text: this.props.intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {person, company, isProfileUpdating} = this.props;
		const allRoles = company.roles.edges.map(edge => ({value: edge.node.id, label: edge.node.name}));
		const departments = company.departments.edges.map(edge => ({value: edge.node.id, label: edge.node.name}));

		const languages = [
			{value: 'DANISH', label: 'Dansk (DK)'},
			{value: 'ENGLISH_US', label: 'English (US)'},
			{value: 'ENGLISH_UK', label: 'English (UK)'},
			{value: 'ENGLISH_EU', label: 'English (EU)'},
			{value: 'FRENCH', label: 'Français (FR)'},
		];
		if (person.language === 'SPANISH') {
			languages.push({value: 'SPANISH', label: 'Español (ES)'});
		}

		const viewUser = this.props.viewUser;
		const weekdays =
			this.props.language === 'ENGLISH_US'
				? [
						formatMessage({id: 'common.sunday'}),
						formatMessage({id: 'common.monday'}),
						formatMessage({id: 'common.tuesday'}),
						formatMessage({id: 'common.wednesday'}),
						formatMessage({id: 'common.thursday'}),
						formatMessage({id: 'common.friday'}),
						formatMessage({id: 'common.saturday'}),
				  ]
				: [
						formatMessage({id: 'common.monday'}),
						formatMessage({id: 'common.tuesday'}),
						formatMessage({id: 'common.wednesday'}),
						formatMessage({id: 'common.thursday'}),
						formatMessage({id: 'common.friday'}),
						formatMessage({id: 'common.saturday'}),
						formatMessage({id: 'common.sunday'}),
				  ];

		const holidayCalendarOptions = [{value: null, label: formatMessage({id: 'settings.rate_card_default'})}].concat(
			this.props.company.holidayCalendars.edges.map(holidayCalendarEdge => {
				return {value: holidayCalendarEdge.node.id, label: holidayCalendarEdge.node.name};
			})
		);
		const forecast_user = localStorage.getItem('forecast_user');
		const webFrontVersion = versionManager.getClientMajorVersion() + '.' + versionManager.getClientMinorVersion();
		const webFrontVersionTime = moment(versionManager.getClientVersionTime());
		const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

		let unit4Username = '';
		if (this.props.person.unit4UserObject && this.state.unit4_users) {
			const unit4User = this.state.unit4_users.find(u => parseInt(u.personId) === this.props.person.unit4UserObject);
			unit4Username = unit4User
				? unit4User.firstName.trim() + ' ' + unit4User.surname.trim()
				: this.props.person.unit4UserObject;
		}

		let jiraCloudUsername = '';
		if (!this.state.jira_cloud_loading && this.state.jira_cloud_users) {
			const jiraUser = this.state.jira_cloud_users.find(u => u.value === this.props.person.jiraCloudId);
			if (jiraUser) jiraCloudUsername = jiraUser.label;
		}

		let jiraServerUsername = '';
		if (!this.state.jira_server_loading && this.state.jira_server_users) {
			const jiraUser = this.state.jira_server_users.find(u => u.value === this.props.person.jiraServerId);
			if (jiraUser) jiraServerUsername = jiraUser.label;
		}

		let bambooHRUsername = '';
		if (!this.state.bambooHRLoading && this.state.bambooHRUsers) {
			const bambooUser = this.state.bambooHRUsers.find(u => u.value === this.props.person.bambooHRId);
			if (bambooUser) bambooHRUsername = bambooUser.label;
		}

		let slackUsername = '';
		if (!this.state.slackLoading && this.state.slackUsers) {
			const slackUser = this.state.slackUsers.find(u => u.value === this.props.person.slackId);
			if (slackUser) slackUsername = slackUser.label;
		}

		const labels = company.labels.edges.map(edge => edge.node);
		return (
			<div className="section-content settings-app settings-profile" data-cy={'profile-page'}>
				{this.props.children}
				{this.getTopHeader()}
				{this.props.isViewer && <SettingsProfileSubNav viewer={this.props.viewer} />}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="personal-info">
								<div className="info-header">
									<h3>
										{viewUser
											? person.client
												? formatMessage({id: 'settings_profile.client_user_profile_info'})
												: formatMessage({id: 'settings_profile.team-member-info'})
											: formatMessage({id: 'settings_profile.personal-info'})}
									</h3>
								</div>

								<p className="description">
									{viewUser ? (
										person.client ? (
											<FormattedMessage id="settings_profile.client_user_info" />
										) : (
											<FormattedMessage id="settings.team_member_info" />
										)
									) : (
										<FormattedMessage id="settings.profile_info" />
									)}
								</p>

								<div className="titles-material">
									<InputField
										cy={'member-name'}
										label={formatMessage({id: 'common.name'})}
										type="text"
										placeholder={
											viewUser
												? person.client
													? formatMessage({id: 'settings_profile.enter_client_user_name'})
													: formatMessage({id: 'settings_profile.enter-team-member-name'})
												: formatMessage({id: 'settings_profile.enter-your-name'})
										}
										value={this.state.name}
										onBlur={this.handleBlurName.bind(this)}
										onChange={this.handleChangeName.bind(this)}
										blurOnEnter={true}
									/>
									{person.client ? (
										<InputField
											label={formatMessage({id: 'common.phone'})}
											type="text"
											placeholder={formatMessage({id: 'project-client.contact-phone-input-placeholder'})}
											value={this.state.phone}
											onChange={this.handleChangePhone.bind(this)}
											onBlur={this.handleBlurPhone.bind(this)}
											blurOnEnter={true}
											maxLength={45}
										/>
									) : null}
									<InputField
										cy={'member-email'}
										label={formatMessage({id: 'common.email'})}
										locked={person.demoUser}
										type="email"
										placeholder={
											viewUser
												? person.demoUser
													? formatMessage({id: 'settings_profile.demo_user_no_email'})
													: person.client
													? formatMessage({id: 'settings_profile.enter_client_user_email'})
													: formatMessage({id: 'settings_profile.enter-team-member-email'})
												: formatMessage({id: 'settings_profile.enter-your-email'})
										}
										id="email_address"
										value={this.state.email}
										onChange={this.handleChangeEmail.bind(this)}
										onBlur={this.handleBlurEmail.bind(this)}
										checkEmailValidity={this.checkEmailValidity.bind(this)}
										errorMessage={
											this.props.emailValidationErrorMessage &&
											this.props.emailValidationErrorMessage !== ''
												? this.props.emailValidationErrorMessage
												: formatMessage({id: 'settings_profile.invalid_email'})
										}
										inputValid={!this.state.showEmailValidationError}
										blurOnEnter={true}
									/>
									<InputField
										cy={'member-title'}
										label={formatMessage({id: 'common.title'})}
										type="text"
										placeholder={formatMessage({id: 'first_login.enter_job_title'})}
										value={this.state.job_title}
										onChange={this.handleChangeJobTitle.bind(this)}
										onBlur={this.handleBlurTitle.bind(this)}
										blurOnEnter={true}
									/>
									{viewUser && !person.client ? <TeamMemberStatusSection person={person} /> : null}
									<div className="dates-container" data-userpilot="profile-dates">
										<DatePicker
											startDate={this.state.startDate ? moment(this.state.startDate) : null}
											label={formatMessage({id: 'common.start_date'})}
											handleDateRangeChange={this.handleStartDateChange.bind(this)}
											clearBothDates={this.handleClearStartDate.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.SLIM}
											isSingleDatePicker
											clearable
											endDateLimite={
												this.state.endDate ? moment(this.state.endDate).add(1, 'days') : null
											}
											hideLimitMessage
											disabled={!isAdmin}
										/>
										<div className="date-separator">-</div>
										<DatePicker
											startDate={this.state.endDate ? moment(this.state.endDate) : null}
											label={formatMessage({id: 'common.end_date'})}
											handleDateRangeChange={this.handleEndDateChange.bind(this)}
											clearBothDates={this.handleClearEndDate.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.SLIM}
											isSingleDatePicker
											clearable
											startDateLimite={this.state.startDate ? moment(this.state.startDate) : null}
											hideLimitMessage
											disabled={!isAdmin}
										/>
									</div>
								</div>
								<div className="user">
									<div className={this.props.isUploading ? 'upload-overlay' : 'upload-overlay-hidden'}>
										<div className={this.props.isUploading ? 'uploading-gif' : 'uploading-gif-hidden'} />
									</div>
									{this.props.person.profilePictureId ? (
										<img
											crossOrigin="use-credentials"
											alt=""
											src={profilePicSrc(this.props.person.profilePictureId)}
											width="140"
											height="140"
										/>
									) : (
										<PersonInitials
											size={INITIALS_SIZE.EXTRA_LARGE}
											initials={this.props.person.initials}
										/>
									)}

									<div className="controls">
										<div className="controls-update">
											<input
												id="file-input"
												type="file"
												onChange={this.changeProfilePicture.bind(this)}
												ref={input => (this.file_input = input)}
												onClick={e => {
													e.target.value = null;
												}}
											/>
											<label className="control update">
												<FormattedMessage id="settings_profile.update-photo" />
											</label>
										</div>
										<div className="control remove" onClick={this.deleteProfilePicture.bind(this)}>
											<FormattedMessage id="settings_profile.remove-photo" />
										</div>
									</div>
								</div>
							</fieldset>
							{person.client ? null : (
								<>
									<fieldset className="role-settings">
										<h3>
											<FormattedMessage id="settings_profile.role-labels-permissions" />{' '}
										</h3>
										<h4>
											<FormattedMessage id="common.role" />
										</h4>
										<p className="description">
											{viewUser ? (
												<FormattedMessage id="settings_profile.team-member-role-description" />
											) : (
												<FormattedMessage id="settings_profile.your-role-description" />
											)}
										</p>
										<SelectInput
											cy={'role-dropdown'}
											listDataCy={'role-option'}
											value={person.role ? person.role.id : null}
											options={allRoles}
											onChange={this.handleSelectRoleChange.bind(this)}
											placeholder={formatMessage({id: 'settings_profile.choose-role'})}
											label={formatMessage({id: 'common.role'})}
											multi={false}
											locked={!isAdmin}
										/>
										<div>
											<h4>
												<FormattedMessage id="common.labels" />
											</h4>
											<p className="description">
												{viewUser ? (
													<FormattedMessage id="settings_profile.select-labels-description-teammember" />
												) : (
													<FormattedMessage id="settings_profile.select-labels-description-viewer" />
												)}
											</p>
											<div className="skills-wrapper">
												<LabelDropdown
													labels={labels}
													selectedItems={this.state.selectedLabels}
													setSelectedItems={this.updateLabels}
													selectedGroupName={formatMessage({id: 'common.added'})}
													data-cy={'team-labels-dropdown'}
													name={formatMessage({
														id: 'settings_profile.select-labels',
													})}
												/>
											</div>
										</div>
										{Util.hasDepartments(this.props.viewer.company.modules)
											? [
													<h4>
														<FormattedMessage id="common.organization" />
													</h4>,
													<div className="organization-div">
														<SelectInput
															cy={'department-dropdown'}
															listDataCy={'department-option'}
															value={person.department ? person.department.id : null}
															options={departments}
															onChange={this.handleSelectDepartmentChange.bind(this)}
															placeholder={formatMessage({
																id: 'settings_profile.choose_department',
															})}
															label={formatMessage({id: 'common.department'})}
															multi={false}
															locked={!viewUser && !isAdmin}
														/>
														{person.costCenter ? (
															<InputField
																cy={'cost-center-title'}
																locked={true}
																label={formatMessage({id: 'common.cost_center'})}
																type="text"
																placeholder={formatMessage({id: 'common.no_cost_center'})}
																value={person.costCenter}
																autosize={true}
															/>
														) : (
															''
														)}
													</div>,
											  ]
											: ''}
									</fieldset>
								</>
							)}
							{person.client ? null : (
								<fieldset className="permissions">
									<h3>
										<FormattedMessage id="settings.permission_profile" />
									</h3>
									<div className="permissions-info-wrapper">
										<p className="description">
											{viewUser ? (
												<FormattedMessage id="settings_profile.permissions-desc-team" />
											) : (
												<FormattedMessage id="settings_profile.permissions-desc-viewer" />
											)}
										</p>
									</div>
									{this.props.showBillingInfo ? (
										<FormattedMessage id="settings_profile.billing_info" />
									) : null}
									<SelectInput
										multi={hasModule(MODULE_TYPES.SISENSE)}
										showValuesInline={true}
										value={
											hasModule(MODULE_TYPES.SISENSE)
												? person.profiles.edges.map(e => {
														return {
															value: e.node.id,
															label: e.node.name,
														};
												  })
												: person.profiles.edges[0]?.node.id
										}
										options={getSelectableProfiles(this.props.viewer.company.profiles.edges)
											.filter(profile => this.props.viewUser || !personIsVirtualUser(profile))
											.map(profile => {
												return {
													label: profile.name,
													value: profile.id,
													profile: profile,
													disabled:
														(!this.props.viewUser && personIsAdmin(profile)) || isProfileUpdating,
												};
											})}
										onChange={this.handleSelectProfileChange.bind(this)}
										placeholder={getProfileName(
											this.props.viewer.company.profiles.edges.map(profile => profile.node),
											person.profiles.edges[0]?.node.id
										)}
										label={formatMessage({id: 'settings.permission_profile'})}
										locked={
											(!viewUser &&
												(!hasModule(MODULE_TYPES.SISENSE) ||
													!hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS))) ||
											((!person.invited || person.email === '') && personIsVirtualUser(person))
										}
									/>
								</fieldset>
							)}
							{Util.hasSkills() && (
								<fieldset className="skills">
									<ProfileSkills person={person} company={company} />
								</fieldset>
							)}
							{person.client ? null : (
								<fieldset className="working-hours">
									<h3>
										<FormattedMessage id="common.working_hours" />
									</h3>
									<div>
										<table className="working-hours-table">
											<thead>
												<tr>
													{weekdays.map((day, index) => (
														<th key={index}>{day}</th>
													))}
													<th>&nbsp;</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													{[...Array(7)].map((_, i) => (
														<td key={i} className="input-cell">
															<HoursInput
																value={this.state.working_hours[i]}
																nonWorkingDay={this.state.working_hours[i] === 0}
																mutation={this.saveWorkingHours.bind(this, i)}
																disabled={Util.isWeekdayDisabled(
																	i,
																	this.props.company,
																	this.props.viewer.language
																)}
															/>
														</td>
													))}
												</tr>
											</tbody>
										</table>
									</div>
									<SelectInput
										cy={'holiday-calendar-input'}
										listDataCy={'holiday-option'}
										placeholder={formatMessage({id: 'settings_holiday_calendars.select_holiday_calendar'})}
										options={holidayCalendarOptions}
										onChange={this.handleHolidayCalendarChange.bind(this)}
										value={person.holidayCalendar ? person.holidayCalendar.id : null}
										clearable={false}
										label={formatMessage({id: 'settings_holiday_calendars.holiday_calendar'})}
										multi={false}
									/>
								</fieldset>
							)}

							<fieldset className="locale-settings">
								<h3>
									<FormattedMessage id="settings_profile.language-dates-numbers" />
								</h3>
								<p className="description">
									{viewUser
										? person.client
											? formatMessage({id: 'settings_profile.language_description_client_user'})
											: formatMessage({id: 'settings_profile.language-description-team-member'})
										: formatMessage({id: 'settings_profile.language-description'})}
								</p>
								<SelectInput
									listDataCy={'localization-option'}
									cy={'localization-preferences'}
									placeholder={formatMessage({id: 'settings_profile.choose-language'})}
									options={languages}
									onChange={this.handleSelectLanguageChange.bind(this)}
									value={person.language}
									clearable={false}
									label={formatMessage({id: 'settings.profile-language'})}
									multi={false}
								/>
							</fieldset>

							{hasModule(MODULE_TYPES.SISENSE) && (
								<fieldset>
									<h3>{'Advanced Analytics'}</h3>
									<p className="description">
										{`${viewUser ? 'This person is' : 'You are'} ${
											person.sisenseUserId ? '' : 'not'
										} linked to an Advanced Analytics user.`}
									</p>
									{person.sisenseUserId && (
										<Button
											onClick={this.deleteSisenseUser.bind(this)}
											size={Button.SIZE.STANDARD}
											variant={Button.VARIANT.RED_FILLED}
										>
											Delete User
										</Button>
									)}
								</fieldset>
							)}

							{this.state.harvest_loading ? (
								<fieldset className="harvest-settings">
									<div className="harvest-title">
										<img width={30} height={30} src={HarvestIcon} alt="Harvest" />
										<h3 className="harvest-header">
											<FormattedMessage id="title.app_harvest" />
										</h3>
									</div>

									<h4>
										<FormattedMessage id="common.loading" />
									</h4>
								</fieldset>
							) : this.props.company.harvestEnabled ? (
								<fieldset className="harvest-settings">
									<div className="harvest-title">
										<img width={30} height={30} src={HarvestIcon} alt="Harvest" />
										<h3 className="harvest-header">
											<FormattedMessage id="title.app_harvest" />
										</h3>
									</div>

									<div className={'harvest-description'}>
										<FormattedMessage id="settings.linked_harvest_user" />
									</div>
									<div>{this.state.harvest_users_name}</div>
								</fieldset>
							) : null}

							{!person.client && (person.calendarKey !== null || company.calendarApiEnabled) ? (
								<fieldset className="calendar-integration-settings">
									<h3>Calendar Integration</h3>
									{person.calendarKey !== null ? (
										<>
											<h4>Sync task deadlines to your own calendar</h4>
											<div className="description">
												Stay in the loop with your ongoing task deadlines by syncing it to your calendar
												of choice: G Cal, Outlook Cal and Apple Calendar.
											</div>
											<div className="description">
												{viewUser
													? 'This is the persons private url.'
													: 'This is your private calendar link, paste it into your calendar tool to enable your events.'}
											</div>
											<div className="input-wrapper">
												<InputField
													label={formatMessage({id: 'common.link'})}
													value={'https://api.forecast.it/calendar/' + person.calendarKey}
													disabled={true}
													type="text"
													placeholder=""
												/>
												<ClipboardButton
													className="copy-button"
													title={formatMessage({id: 'common.copy'})}
													data-clipboard-text={
														'https://api.forecast.it/calendar/' + person.calendarKey
													}
												/>
											</div>
										</>
									) : null}
									{(person.calendarKey !== null || company.calendarApiEnabled) &&
									company.calendarApiEnabled ? (
										<div className={'section-border'} />
									) : null}
									{company.calendarApiEnabled && !this.props.viewUser ? (
										<SettingsCalendarWrapper
											viewer={this.props.viewer}
											personId={this.props.person.actualPersonId ?? this.props.person.id}
										/>
									) : null}
								</fieldset>
							) : null}
							{
								// GitLab
								isAdmin && company.gitlabEnabled ? (
									<fieldset className="gitlab-integration-settings">
										<h3>GitLab.com</h3>
										<p className="description">
											<FormattedMessage id="settings_profile.gitlab_description" />
										</p>
										{this.props.person.gitlabUser && this.props.person.gitlabUser.integrationUserId ? (
											this.state.linked_gitlab_user ? (
												<div className="gitlab-user-container">
													<div className="gitlab-user">
														<img
															height="42"
															width="42"
															src={this.state.linked_gitlab_user.avatar_url}
														/>
														<div className="name">
															<b>{this.state.linked_gitlab_user.name}</b>
															<br />
															{'@' + this.state.linked_gitlab_user.username}
														</div>
													</div>
													<button onClick={this.removeGitLabUser.bind(this, false)}>
														<FormattedMessage id="settings_profile.gitlab_unlink" />
													</button>
												</div>
											) : null
										) : (
											<div>
												<div className="input-wrapper">
													<InputField
														label={formatMessage({id: 'settings_profile.gitlab_username'})}
														type="text"
														placeholder={formatMessage({id: 'settings_profile.gitlab_username'})}
														onChange={this.handleGitlabSearchChange.bind(this, false)}
														onEnter={this.fetchGitLabUsers.bind(this, false)}
													/>
													<button onClick={this.fetchGitLabUsers.bind(this, false)}>
														<FormattedMessage id="common.search" />
													</button>
												</div>
												{this.state.gitlab_loading ? (
													<div>
														<FormattedMessage id="common.loading" />
													</div>
												) : (
													<div className="users">
														{this.state.gitlab_users.map(u => (
															<div
																className="gitlab-user"
																onClick={this.selectGitlabUser.bind(this, u, false)}
															>
																<img height="42" width="42" src={u.avatar_url} />
																<div className="name">
																	<b>{u.name}</b>
																	<br />
																	{'@' + u.username}
																</div>
															</div>
														))}
													</div>
												)}
											</div>
										)}
									</fieldset>
								) : null
							}
							{
								// GitLab Server
								isAdmin && company.gitlabServerEnabled ? (
									<fieldset className="gitlab-integration-settings">
										<h3>GitLab Server</h3>
										<p className="description">
											<FormattedMessage id="settings_profile.gitlab_description" />
										</p>
										{this.props.person.gitlabServerUser &&
										this.props.person.gitlabServerUser.integrationUserId ? (
											this.state.linked_gitlab_server_user ? (
												<div className="gitlab-user-container">
													<div className="gitlab-user">
														<img
															height="42"
															width="42"
															src={this.state.linked_gitlab_server_user.avatar_url}
														/>
														<div className="name">
															<b>{this.state.linked_gitlab_server_user.name}</b>
															<br />
															{'@' + this.state.linked_gitlab_server_user.username}
														</div>
													</div>
													<button onClick={this.removeGitLabUser.bind(this, true)}>
														<FormattedMessage id="settings_profile.gitlab_unlink" />
													</button>
												</div>
											) : null
										) : (
											<div>
												<div className="input-wrapper">
													<InputField
														label={formatMessage({id: 'settings_profile.gitlab_username'})}
														type="text"
														placeholder={formatMessage({id: 'settings_profile.gitlab_username'})}
														onChange={this.handleGitlabSearchChange.bind(this, true)}
														onEnter={this.fetchGitLabUsers.bind(this, true)}
													/>
													<button onClick={this.fetchGitLabUsers.bind(this, true)}>
														<FormattedMessage id="common.search" />
													</button>
												</div>
												{this.state.gitlab_server_loading ? (
													<div>
														<FormattedMessage id="common.loading" />
													</div>
												) : (
													<div className="users">
														{this.state.gitlab_server_users.map(u => (
															<div
																className="gitlab-user"
																onClick={this.selectGitlabUser.bind(this, u, true)}
															>
																<img height="42" width="42" src={u.avatar_url} alt="" />
																<div className="name">
																	<b>{u.name}</b>
																	<br />
																	{'@' + u.username}
																</div>
															</div>
														))}
													</div>
												)}
											</div>
										)}
									</fieldset>
								) : null
							}
							{
								// GitHub
								!person.client && (isAdmin || !this.props.viewUser) && company.githubEnabled ? (
									<fieldset className="github-integration-settings">
										<h3>GitHub</h3>
										<p className="description">
											<FormattedMessage id="settings_profile.github_description" />
										</p>
										{this.props.person.githubUser && this.props.person.githubUser.integrationUserId ? (
											this.state.linked_github_user ? (
												<div className="github-user-container">
													<div className="github-user">
														<img
															height="42"
															width="42"
															src={this.state.linked_github_user.avatar_url}
														/>
														<div className="name">
															<b>{this.state.linked_github_user.username}</b>
															<br />
															{'@' + this.state.linked_github_user.username}
														</div>
													</div>
													<button onClick={this.removeGithubUser.bind(this, true)}>
														<FormattedMessage id="settings_profile.github_unlink" />
													</button>
												</div>
											) : null
										) : (
											<div>
												<div className="input-wrapper">
													<InputField
														label={formatMessage({id: 'settings_profile.github_username'})}
														type="text"
														placeholder={formatMessage({id: 'settings_profile.github_username'})}
														onChange={this.handleGithubSearchChange.bind(this)}
														onEnter={this.fetchGithubUsers.bind(this)}
													/>
													<button onClick={this.fetchGithubUsers.bind(this)}>
														<FormattedMessage id="common.search" />
													</button>
												</div>
												{this.state.github_loading ? (
													<div>
														<FormattedMessage id="common.loading" />
													</div>
												) : (
													<div className="users">
														{this.state.github_users.map(u => (
															<div
																className="github-user"
																onClick={this.selectGithubUser.bind(this, u)}
															>
																<img height="42" width="42" src={u.avatar_url} alt={''} />
																<div className="name">
																	<b>{u.username}</b>
																	<br />
																	{'@' + u.username}
																</div>
															</div>
														))}
													</div>
												)}
											</div>
										)}
									</fieldset>
								) : null
							}
							{
								// Jira
								!person.client && (isAdmin || !this.props.viewUser) && company.jiraCloudEnabled ? (
									<fieldset className="common-integration-settings">
										<h3>Jira Cloud</h3>
										<p className="description">
											{this.props.person.jiraCloudId
												? this.state.jira_cloud_users
													? [
															<FormattedMessage
																id={'settings_profile.jira_linked_user'}
																values={{
																	jiraUser: jiraCloudUsername,
																}}
															/>,
															<div>
																<Button
																	onClick={this.unlinkJiraUser.bind(this, 'cloud')}
																	size={Button.SIZE.STANDARD}
																	variant={Button.VARIANT.RED_FILLED}
																>
																	{formatMessage({id: 'common.unlink'})}
																</Button>
															</div>,
													  ]
													: null
												: [
														<FormattedMessage id="settings_profile.jira_description" />,
														<div className="common-integration-user-dropdown">
															<Dropdown
																value={null}
																options={this.state.jira_cloud_users.filter(u => !u.linked)}
																onChange={val => this.linkJiraUser(val, 'cloud')}
																hideLabel={true}
																placeholder={formatMessage({id: 'integrations.jira_user'})}
																customHeight={30}
																integrationDropdown={true}
																integrationLoading={this.state.jira_cloud_loading}
																integrationRetryToFetch={this.fetchJiraCloudUsers.bind(this)}
															/>
														</div>,
												  ]}
										</p>
									</fieldset>
								) : null
							}
							{
								// Jira Server
								!person.client && (isAdmin || !this.props.viewUser) && company.jiraServerEnabled ? (
									<fieldset className="common-integration-settings">
										<h3>Jira Server</h3>
										<p className="description">
											{this.props.person.jiraServerId ? (
												this.state.jira_server_users ? (
													<>
														<FormattedMessage
															id={'settings_profile.jira_linked_user'}
															values={{
																jiraUser: jiraServerUsername,
															}}
														/>
														<div>
															<Button
																onClick={this.unlinkJiraUser.bind(this, 'server')}
																size={Button.SIZE.STANDARD}
																variant={Button.VARIANT.RED_FILLED}
															>
																{formatMessage({id: 'common.unlink'})}
															</Button>
														</div>
													</>
												) : null
											) : (
												<>
													<FormattedMessage id="settings_profile.jira_description" />
													<div className="common-integration-user-dropdown">
														<Dropdown
															value={null}
															options={this.state.jira_server_users.filter(u => !u.linked)}
															onChange={val => this.linkJiraUser(val, 'server')}
															hideLabel={true}
															placeholder={formatMessage({id: 'integrations.jira_user'})}
															customHeight={30}
															integrationDropdown={true}
															integrationLoading={this.state.jira_server_loading}
															integrationRetryToFetch={this.fetchJiraServerUsers.bind(this)}
														/>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}
							{
								// Unit4 - Show for admin or when viewing your own profile
								!person.client && (isAdmin || !this.props.viewUser) && company.unit4Enabled ? (
									<fieldset className="common-integration-settings">
										<h3>Unit4</h3>
										<p className="description">
											{this.props.person.unit4UserObject ? (
												this.state.unit4_users ? (
													<>
														<FormattedMessage
															id="settings_profile.unit4_linked_user"
															values={{
																unit4User: unit4Username,
															}}
														/>
														<div>
															<Button
																onClick={this.unlinkUnit4User.bind(this)}
																size={Button.SIZE.STANDARD}
																variant={Button.VARIANT.RED_FILLED}
															>
																{formatMessage({id: 'common.unlink'})}
															</Button>
														</div>
													</>
												) : null
											) : (
												<>
													<FormattedMessage id="settings_profile.unit4_description" />
													<div className="common-integration-user-dropdown">
														<Dropdown
															value={null}
															options={this.state.unit4_user_options}
															onChange={val => this.linkUnit4User(val)}
															hideLabel={true}
															placeholder={formatMessage({
																id: 'integrations.unit4_user.placeholder',
															})}
															customHeight={30}
															integrationDropdown={true}
															integrationLoading={this.state.unit4_loading}
															onSearchValueChange={this.fetchUnit4Resources.bind(this)}
														/>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}

							{
								// BambooHR - Show for admin or when viewing your own profile
								!person.client && (isAdmin || !this.props.viewUser) && company.bambooHREnabled ? (
									<fieldset className="common-integration-settings">
										<h3>BambooHR</h3>
										<p className="description">
											{this.props.person.bambooHRId ? (
												this.state.bambooHRUsers ? (
													<>
														<FormattedMessage
															id="settings_profile.bamboohr_linked_user"
															values={{
																bamboohrUser: bambooHRUsername,
															}}
														/>
														<div>
															<Button
																onClick={() => this.setBambooHRUser()}
																size={Button.SIZE.STANDARD}
																variant={Button.VARIANT.RED_FILLED}
															>
																{formatMessage({id: 'common.unlink'})}
															</Button>
														</div>
													</>
												) : null
											) : (
												<>
													<FormattedMessage id="settings_profile.bamboohr_description" />
													<div className="common-integration-user-dropdown">
														<Dropdown
															value={null}
															options={this.state.bambooHRUsers}
															onChange={val => this.setBambooHRUser(val)}
															hideLabel={true}
															placeholder={formatMessage({id: 'integrations.bamboohr_user'})}
															customHeight={30}
															integrationDropdown={true}
															integrationLoading={this.state.bambooHRLoading}
															integrationRetryToFetch={this.fetchBambooHRUsers.bind(this)}
														/>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}
							{
								// Sage Intacct - Show for non-client, admin, or when viewing your own profile
								!person.client &&
								(isAdmin || !this.props.viewUser) &&
								hasFeatureFlag('sage_intacct_integration') &&
								company.sageIntacctConnectionEnabled ? (
									<fieldset className="common-integration-settings">
										<h3>Sage Intacct</h3>
										<p className="description">
											{this.props.person.sageIntacctId ? (
												<>
													{`${
														this.props.person.firstName
													} is linked to the following Sage Intacct profile: ${
														this.props.person.sageIntacctName
													} ${
														this.props.person?.sageIntacctEmail
															? `(${this.props.person.sageIntacctEmail})`
															: ''
													}`}
													<div>
														<Button
															onClick={() => this.setSageIntacctUser()}
															size={Button.SIZE.STANDARD}
															variant={Button.VARIANT.RED_FILLED}
														>
															{formatMessage({id: 'common.unlink'})}
														</Button>
													</div>
												</>
											) : (
												<>
													{`Select a profile
                            from the list below to associate this team member
                            with a Sage Intacct profile.`}
													<div className="common-integration-user-dropdown">
														<Dropdown
															value={null}
															options={this.state.sageIntacctUsers}
															onChange={val => this.setSageIntacctUser(val)}
															hideLabel={true}
															placeholder={`Select Sage Intacct profile`}
															customHeight={30}
															integrationDropdown={true}
															integrationLoading={this.state.sageIntacctLoading}
															integrationRetryToFetch={this.fetchSageIntacctUsers.bind(this)}
														/>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}
							{
								// Slack - Show when viewing your own profile
								!person.client && company.newSlackEnabled ? (
									<fieldset className="common-integration-settings">
										<h3>Slack</h3>
										<p className="description">
											{this.props.person.slackId ? (
												this.state.slackUsers ? (
													<>
														<FormattedMessage
															id="settings_profile.slack_linked_user"
															values={{
																slackUser: slackUsername,
															}}
														/>
														<div>
															<Button
																onClick={() => this.unlinkSlackUser()}
																size={Button.SIZE.STANDARD}
																variant={Button.VARIANT.RED_FILLED}
															>
																{formatMessage({id: 'common.unlink'})}
															</Button>
														</div>
													</>
												) : null
											) : (
												<>
													<FormattedMessage id="settings_profile.slack_description" />
													<div>
														<Button
															onClick={() => this.linkSlackUser()}
															size={Button.SIZE.STANDARD}
															variant={Button.VARIANT.GREEN_FILLED}
														>
															{formatMessage({id: 'common.link'})}
														</Button>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}
							{
								// Azure DevOps - Show for non-client, admin, or when viewing your own profile
								!person.client && (isAdmin || !this.props.viewUser) && company.vstsEnabled ? (
									<fieldset className="common-integration-settings">
										<h3>Azure DevOps</h3>
										<p className="description">
											{this.props.person.adoUserId ? (
												<>
													{`${this.props.person.firstName} is linked to an Azure DevOps team member`}
													<div>
														<Button
															onClick={() => this.unlinkAdoUser()}
															size={Button.SIZE.STANDARD}
															variant={Button.VARIANT.RED_FILLED}
														>
															{formatMessage({id: 'common.unlink'})}
														</Button>
													</div>
												</>
											) : (
												<>
													{`Link this user to an Azure DevOps team member`}
													<div>
														<Button
															onClick={() => this.linkAdoUser()}
															size={Button.SIZE.STANDARD}
															variant={Button.VARIANT.GREEN_FILLED}
														>
															{formatMessage({id: 'common.link'})}
														</Button>
													</div>
												</>
											)}
										</p>
									</fieldset>
								) : null
							}

							{forecast_user === 'true' && (
								<div title={'web fron version'} className={'web-fron-version'}>
									version {webFrontVersion} {webFrontVersionTime.format('DD/MM/YY HH:mm')}
								</div>
							)}
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

SettingsProfile.propTypes = {
	person: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	viewUser: PropTypes.bool,
	changeName: PropTypes.func.isRequired,
	changeEmail: PropTypes.func.isRequired,
	changeJobTitle: PropTypes.func.isRequired,
	changeRole: PropTypes.func.isRequired,
	changeLanguage: PropTypes.func.isRequired,
	showEmailValidationError: PropTypes.bool.isRequired,
	emailValidationErrorMessage: PropTypes.string.isRequired,
	changeProfilePicture: PropTypes.func.isRequired,
	deleteProfilePicture: PropTypes.func.isRequired,
	isUploading: PropTypes.bool.isRequired,
	// showPermissionsInfo: PropTypes.func.isRequired,
	language: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
};

export default injectIntl(SettingsProfile, {forwardRef: true});
